import type { PaperProps } from '@mui/material/Paper';
import type { SxProps, Theme } from '@mui/material/styles';
import type { PropsWithChildren } from 'react';

import Popover, { popoverClasses, PopoverProps } from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { spreadMuiSxes } from '@/utils/styles';
import { CloseButton } from '@components/Dialog';

import { POPOVER_PAPER_DEFAULT_SX } from './constants';

export const AppPopoverTitle: React.FC<
  PropsWithChildren<{
    onClose: () => void;
    sx?: SxProps<Theme>;
    slotSxes?: {
      root?: SxProps<Theme>;
      closeButton?: SxProps<Theme>;
    };
  }>
> = ({ children, onClose, sx, slotSxes }) => (
  <Stack
    flexDirection="row"
    alignItems="center"
    p="16px 16px 12px"
    sx={[...spreadMuiSxes(sx), ...spreadMuiSxes(slotSxes?.root)]}
    //
  >
    {typeof children === undefined || typeof children === 'string' ? (
      <Typography variant="h5" color="neutralV2.0">
        {children}
      </Typography>
    ) : (
      children
    )}
    <CloseButton sx={slotSxes?.closeButton} onClose={onClose} />
  </Stack>
);

export const AppPopoverFooter = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px',
  borderTop: '1px solid',
  borderColor: `${theme.palette.neutralV2[5]}`,
  padding: '16px',
}));

type AppPopoverProps = PopoverProps;

export const AppPopover = styled(Popover)<AppPopoverProps>(
  ({ theme, slotProps }) => ({
    overflow: 'hidden',
    [`.${popoverClasses.paper}`]: theme.unstable_sx([
      ...spreadMuiSxes(POPOVER_PAPER_DEFAULT_SX),
      ...spreadMuiSxes((slotProps?.paper as PaperProps)?.['sx']),
    ]),
  }),
);
