export const COLUMN_TYPE_OPTS = [
  'TEXT',
  'DATETIME',
  'NUMBER',
  'BOOLEAN',
] as const;

export const MAX_FILTER_NAME_CHARS = 20;

export const NULL_STRING = 'null';
