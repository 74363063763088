'use client';
import { useEffect, useState } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export const useEditorFocus = () => {
  const [editor] = useLexicalComposerContext();

  const [focus, setFocus] = useState(false);

  useEffect(() => {
    function myFocusListener() {
      setFocus(true);
    }

    function myBlurListener() {
      setFocus(false);
    }

    const removeRootListener = editor.registerRootListener(
      (rootElement, prevRootElement) => {
        // NOTE: register FOCUS event
        rootElement?.addEventListener('focus', myFocusListener);
        prevRootElement?.removeEventListener('focus', myFocusListener);

        // NOTE: register BLUR event
        rootElement?.addEventListener('blur', myBlurListener);
        prevRootElement?.removeEventListener('blur', myBlurListener);
      },
    );

    return () => {
      removeRootListener();
    };
  }, [editor]);

  return focus;
};
