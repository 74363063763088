import invariant from 'tiny-invariant';

import {
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';

import { clientHttp, serverHttp } from './axios';

class CacheApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  async clearDashboardCache({
    teamId,
    dashboardId,
  }: {
    teamId: string;
    dashboardId: string;
  }) {
    const params = new URLSearchParams({
      team_id: teamId,
    });
    const url = `/caches/dashboards/${dashboardId}?${params.toString()}`;
    await this.request(url, 'delete');
  }

  async clearWidgetReportCache({
    teamId,
    dashboardId,
    widgetId,
  }: {
    widgetId: string;
    teamId: string;
    dashboardId: string;
  }): Promise<void> {
    const params = new URLSearchParams({
      team_id: teamId,
      dashboard_id: dashboardId,
    });
    const url = `/caches/widgets/${widgetId}?${params.toString()}`;
    await this.request(url, 'delete');
  }
}

/**
 * @deprecated
 */
export const CacheApiAdapter = Object.freeze(
  new CacheApiAdapterClass(clientHttp),
);

export const CacheApi = {
  onBrowser: () => new CacheApiAdapterClass(clientHttp),
  onServer: () => new CacheApiAdapterClass(serverHttp),
};
