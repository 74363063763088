import type {
  ChangeDashboardOrdersPayload,
  ChangeDashboardOrdersResponse,
  DashboardHealthCheckInput,
  DashboardHealthCheckResult,
  GetDashboardShareSettingsResponse,
  UpdateDashboardShareSettingPayload,
} from '@/feature-dashboard/dashboard/api';
import type {
  DashboardDto,
  TemplateDto,
} from '@/feature-dashboard/dashboard/model';

import invariant from 'tiny-invariant';

import {
  ApiAdapterRequestHeaders,
  BaseApiAdapterClass,
  IApiAdapters,
  type RequestManager,
} from '@/adapters/api/__base';
import {
  ChangeDashboardOrdersResponseSchema,
  GetDashboardShareSettingsResponseSchema,
} from '@/feature-dashboard/dashboard/api';
import {
  DashboardDtoSchema,
  TemplateDtoSchema,
} from '@/feature-dashboard/dashboard/model';
import { NO_PERMISSION_ACCESS_TEAM_API_ERROR } from '@/initializers/AppErrorBoundary';
import { serializeArrayData, serializeData } from '@/utils/data';
import { isUuid } from '@/utils/string';

import { MutateDashboardPayload } from '../../feature-dashboard/dashboard';
import { clientHttp, serverHttp } from './axios';

class DashboardApiAdapterClass
  extends BaseApiAdapterClass
  implements IApiAdapters<DashboardApiAdapterClass>
{
  constructor(rm: RequestManager) {
    super(rm);
  }

  private clone(): DashboardApiAdapterClass {
    return new DashboardApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(
    headers: ApiAdapterRequestHeaders,
  ): DashboardApiAdapterClass {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  async create(teamId: string, payload: MutateDashboardPayload) {
    invariant(teamId, 'no team id provided');

    const url = '/teams/' + teamId + '/dashboards';

    const dashboard = await this.request<DashboardDto, MutateDashboardPayload>(
      url,
      'post',
      payload,
    );

    return serializeData(dashboard, DashboardDtoSchema);
  }

  async changeOrder(teamId: string, payload: ChangeDashboardOrdersPayload[]) {
    invariant(teamId, 'no team id provided');

    const url = '/teams/' + teamId + '/dashboards/orders';

    const data = await this.request<
      ChangeDashboardOrdersResponse[],
      ChangeDashboardOrdersPayload[]
    >(url, 'put', payload);

    return serializeArrayData(data ?? [], ChangeDashboardOrdersResponseSchema);
  }

  async getAll(teamId: string): Promise<DashboardDto[]> {
    invariant(teamId, 'no team id provided');

    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    const dashboards = await this.get<DashboardDto[]>(
      '/teams/' + teamId + '/dashboards',
    );

    if (dashboards == null) return [];

    return serializeArrayData(dashboards, DashboardDtoSchema);
  }

  async getDetail(
    teamId: string,
    dashboardId: string,
  ): Promise<DashboardDto | null> {
    invariant(teamId, 'no team id provided');

    const dashboard = await this.get<DashboardDto>(
      '/teams/' + teamId + '/dashboards/' + dashboardId,
    );

    return serializeData(dashboard, DashboardDtoSchema);
  }

  async getTemplates(teamId: string): Promise<TemplateDto[]> {
    invariant(teamId, 'no team id provided');

    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    const templates = await this.get<TemplateDto[]>(
      '/teams/' + teamId + '/dashboards/templates',
    );

    if (templates == null) return [];

    return serializeArrayData(templates, TemplateDtoSchema);
  }

  async duplicate(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.request<DashboardDto>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/duplicate',
      'post',
    );

    return serializeData(data, DashboardDtoSchema);
  }

  async like(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');

    await this.request<string>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/likes',
      'post',
    );

    return null;
  }

  async unlike(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/likes',
      'delete',
    );

    return null;
  }

  async remove(teamId: string, dashboardId: string): Promise<null> {
    invariant(teamId, 'no team id provided');
    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId,
      'delete',
    );
    return null;
  }

  async update(
    teamId: string,
    dashboardId: string,
    payload: MutateDashboardPayload,
  ) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const url = '/teams/' + teamId + '/dashboards/' + dashboardId;

    const dashboard = await this.request<DashboardDto, MutateDashboardPayload>(
      url,
      'put',
      payload,
    );

    return serializeData(dashboard, DashboardDtoSchema);
  }

  async getSharingSetting(
    teamId: string,
    dashboardId: string,
  ): Promise<GetDashboardShareSettingsResponse | null> {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.get<GetDashboardShareSettingsResponse>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/sharing',
    );

    return serializeData(data, GetDashboardShareSettingsResponseSchema);
  }

  async updateSharingSetting(
    teamId: string,
    dashboardId: string,
    payload: UpdateDashboardShareSettingPayload,
  ): Promise<void> {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/sharing',
      'put',
      payload,
    );
  }

  async filtersHealthCheck(
    teamId: string,
    filters: DashboardHealthCheckInput,
  ): Promise<DashboardHealthCheckResult | null> {
    invariant(teamId, 'no team id provided');

    return await this.request<
      DashboardHealthCheckResult,
      DashboardHealthCheckInput
    >('/teams/' + teamId + '/dashboards/filters-health-check', 'post', filters);
  }
}

/**
 * @deprecated
 */
export const DashboardApiAdapter = Object.freeze(
  new DashboardApiAdapterClass(clientHttp),
);

/**
 * @deprecated
 */
export const DashboardServerApiAdapter = Object.freeze(
  new DashboardApiAdapterClass(serverHttp),
);

export const DashboardApi = {
  onBrowser: () => new DashboardApiAdapterClass(clientHttp),
  onServer: () => new DashboardApiAdapterClass(serverHttp),
};
