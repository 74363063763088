import type { Components, Theme } from '@mui/material/styles';

const PopoverTheme: Components<Omit<Theme, 'components'>> = {
  MuiPopover: {
    styleOverrides: {
      root: {
        overflow: 'scroll',
      },
      paper: ({ theme }) => ({
        padding: '16px 8px',
        background: theme.palette.neutralV2[8],
        boxShadow: `0px 0px 4px 0px rgba(5, 43, 97, 0.12), 2px 6px 12px 0px rgba(0, 0, 0, 0.12)`,
        borderRadius: '10px',
        overflowY: 'auto',
        ['&::-webkit-scrollbar']: {
          width: '4px',
          borderRadius: ' 8px',
          backgroundColor: theme.palette.neutral[6],
        },
        ['&::-webkit-scrollbar-thumb']: {
          borderRadius: '4px',
          backgroundColor: theme.palette.neutral[4],
        },
      }),
    },
  },
};

export default PopoverTheme;
