import type {
  ReportRequestBody,
  ReportTableData,
} from '@/feature-report/types';

import { z } from 'zod';

import {
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';
import { zColumnType } from '@/models/view';
import { serializeData } from '@/utils/data';

import { bigNumberHttp, clientHttp, serverHttp } from './axios';

export const ReportDataResponseSchema = z.object({
  totalRows: z.number(),
  page: z.number(),
  results: z.array(z.record(z.any())).default([]),
  errors: z
    .object({
      custom_formulas: z.record(z.string()),
    })
    .nullish(),
});

const zValidationResponse = z.object({
  is_valid: z.boolean(),
  columns: z
    .array(
      z.object({
        name: z.string(),
        data_type: zColumnType,
      }),
    )
    .nullish(),
  invalid_reason: z.string().nullish(),
});

type ValidationResponse = z.infer<typeof zValidationResponse>;

class ReportApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  async query(
    requestBody: ReportRequestBody | null,
    requestConfig?: { signal?: AbortSignal },
  ): Promise<ReportTableData | null> {
    if (!requestBody) {
      return Promise.reject(new Error('Body must not be null'));
    }

    const res = await this.request<ReportTableData, ReportRequestBody>(
      '/reports',
      'post',
      requestBody,
      {},
      {},
      {
        ...requestConfig,
        responseType: requestBody.responseType ? 'blob' : 'json',
        timeout: 10 * 1000 * 60, // 10 mins
      },
    );

    if (!res) return null;

    if (requestBody.responseType) {
      return res;
    }

    return serializeData(res, ReportDataResponseSchema);
  }

  async validate(
    requestBody: ReportRequestBody | null,
    requestConfig?: { signal?: AbortSignal },
  ): Promise<ValidationResponse | null> {
    //
    if (!requestBody) {
      return Promise.reject(new Error('Body must not be null'));
    }

    const res = await this.request<ValidationResponse, ReportRequestBody>(
      '/reports/validate',
      'post',
      requestBody,
      {},
      {},
      {
        ...requestConfig,
        responseType: requestBody.responseType ? 'blob' : 'json',
      },
    );

    if (!res) return null;

    return res;
  }
}

/**
 * @deprecated
 */
export const ReportClientApiAdapter = Object.freeze(
  new ReportApiAdapterClass(clientHttp),
);
/**
 * @deprecated
 */
export const ReportWithBigNumnerApi = Object.freeze(
  new ReportApiAdapterClass(bigNumberHttp),
);

export const ReportApi = {
  onBrowser: () => new ReportApiAdapterClass(clientHttp),
  onBrowserBigNumber: () => new ReportApiAdapterClass(bigNumberHttp),
  // onBrowser: () => new ReportApiAdapterClass(bigNumberHttp),
  onServer: () => new ReportApiAdapterClass(serverHttp),
};
