const API_VERSION = '';

export const teamsURL = {
  teamsId: (id: string) => `${API_VERSION}/teams/${id}`,
  permissions: (teamId: string) => teamsURL.teamsId(teamId) + '/permissions',
};

export const connectionURL = {
  getAll: (teamId: string) => `${teamsURL.teamsId(teamId)}/connections`,
  getById: (teamId: string, connectionId: string) =>
    `${connectionURL.getAll(teamId)}/${connectionId}`,
  syncById: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/sync`,
  downloadSyncHistories: (
    teamId: string,
    connectionId: string,
    historyId: string,
  ) =>
    `${connectionURL.getById(
      teamId,
      connectionId,
    )}/sync-histories/${historyId}/download`,
  statistics: (teamId: string) => `${connectionURL.getAll(teamId)}/statistics`,
  position: (teamId: string) => `${connectionURL.getAll(teamId)}/positions`,
  schedules: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/schedules`,
  schedulesId: (teamId: string, connectionId: string, scheduleId: string) =>
    `${connectionURL.schedules(teamId, connectionId)}/${scheduleId}`,
  schemas: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/schemas`,
  columns: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/columns`,
  syncHistories: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/sync-histories`,
  downloadByUrl: (teamId: string, connectionId: string, historyId: string) =>
    `${connectionURL.getById(
      teamId,
      connectionId,
    )}/sync-histories/${historyId}/download-url`,
};

export const dataSourcesURL = {
  getAll: () => `${API_VERSION}/data-sources`,
  getById: (dsId: string) => `${dataSourcesURL.getAll()}/${dsId}`,
};

export const dataModelsURL = {
  getAll: (teamId: string) => `${teamsURL.teamsId(teamId)}/datasets`,

  getById: (teamId: string, datasetId: string) =>
    `${dataModelsURL.getAll(teamId)}/${datasetId}/data-models`,

  getByDataModelId: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getById(teamId, datasetId)}/${dataModelId}`,

  editDataModel: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getById(teamId, datasetId)}/${dataModelId}`,

  getDataModelsDetail: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) => dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId),

  editDataModelQueryString: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) =>
    `${dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId)}/query`,

  getDownstreams: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(
      teamId,
      datasetId,
      dataModelId,
    )}/downstreams`,

  getRelatedItems: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(
      teamId,
      datasetId,
      dataModelId,
    )}/related-items`,

  getDataModelQuery: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId)}/query`,

  duplicateDataModels: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) =>
    `${dataModelsURL.getByDataModelId(
      teamId,
      datasetId,
      dataModelId,
    )}/duplicate`,

  getConfig: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId)}/config`,

  filterByDatasetId: (teamId: string) =>
    `${dataModelsURL.getAll(teamId)}/filter`,
};

export const dataTransformationUrlMap = {
  _base(teamId: string) {
    return `${API_VERSION}/teams/${teamId}`;
  },
  templates: () => `${API_VERSION}/templates` as const,
  collections(teamId: string) {
    return `${this._base(teamId)}/collections`;
  },
  batchDeleteCollection(teamId: string) {
    return `${this._base(teamId)}/collections/bulk-delete`;
  },
  templateConnections(teamId: string, templateId: string) {
    return `${this._base(
      teamId,
    )}/transformations/templates/${templateId}/available-connections`;
  },
  templatePreviewQuery(teamId: string, templateId: string) {
    return `${this._base(
      teamId,
    )}/transformations/templates/${templateId}/preview-query`;
  },
  executeQuery(teamId: string) {
    return `${this._base(teamId)}/transformations/queries`;
  },
  createDataModelFromQuery(teamId: string) {
    return `${this._base(teamId)}/transformations/data-models`;
  },
  getCollectionById: (teamId: string, collectionId: string) =>
    [API_VERSION, 'teams', teamId, 'collections', collectionId].join('/'),
  getDagNodes(teamId: string) {
    return `${this._base(teamId)}/transformations/dag`;
  },
  editDagNodesConfig(teamId: string) {
    return `${this.getDagNodes(teamId)}/item-configs`;
  },
};
