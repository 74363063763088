import type { BoxProps, Theme } from '@mui/material';

import React, { forwardRef } from 'react';

import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';

import { CLASSNAME_ICON_CIGRO } from '@/constants';
import { renderPath } from '@/utils/cell';

const StyledBox = styled(Box, {
  shouldForwardProp(propName) {
    if (propName === 'ownerState') return false;
    return true;
  },
})``;

type AppIconProps = {
  className?: string;
  name: string;
  size?: number;
  color?: string | ((theme: Theme) => string | undefined);
  sx?: React.ComponentProps<typeof Box>['sx'];
} & BoxProps;

const AppIcon = forwardRef<HTMLElement, AppIconProps>(
  ({ name, size = 24, color, className, sx, children, ...boxProps }, ref) => {
    const theme = useTheme();
    let sxValues = typeof sx === 'function' ? sx(theme) : sx;
    const fontSize = size ? size + 'px' : undefined;
    const parsedColor = typeof color === 'function' ? color(theme) : color;
    const fontColor = parsedColor || undefined;

    switch (name) {
      case 'toast-warning': {
        children = (
          <>
            <Box
              component="span"
              className="path1"
              sx={{
                ':before': {
                  color:
                    (parsedColor || theme.palette.semantic.negative100) +
                    '!important',
                },
              }}
            />
            <Box component="span" className="path2" />
            <Box component="span" className="path3" />
          </>
        );
        break;
      }
      case 'toast-success': {
        children = (
          <>
            <Box
              component="span"
              className="path1"
              sx={{
                ':before': {
                  color:
                    (parsedColor || theme.palette.semantic.positive) +
                    ' !important',
                },
              }}
            />
            <Box component="span" className="path2" />
          </>
        );
        break;
      }
      case 'ic_image_source': {
        children = renderPath(7);
        break;
      }
      case 'ic_team_profile_80': {
        children = renderPath(4);
        break;
      }
      case 'ic_delete_16': {
        children = renderPath(3);
        break;
      }
      case 'ic_toast_20_warning_dark': {
        children = (
          <>
            <Box component="span" className="path1" id="da" />
            <Box component="span" className="path2" />
            <Box component="span" className="path3" />
          </>
        );
        sxValues = {
          [`&.icon-ic_toast_20_warning_dark .path3:before`]: {
            color: fontColor,
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_filter_24_on': {
        children = renderPath(9);
        break;
      }
      case 'ic_filter_24_off': {
        children = children = renderPath(9);
        break;
      }
      case 'ic_filters_24_option': {
        children = renderPath(9);
        break;
      }
      case 'ic_circle_checked': {
        children = renderPath(3);
        break;
      }
      case 'ic_circle_checkbox': {
        children = renderPath(2);
        break;
      }
      case 'ic_cb_active': {
        children = renderPath(2);
        sxValues = {
          [`&.icon-ic_cb_active .path1:before`]: {
            color: fontColor,
          },
        };
        break;
      }
      case 'ic_cb_indeterminate': {
        children = renderPath(2);
        sxValues = {
          [`&.icon-ic_cb_indeterminate .path1:before`]: {
            color: fontColor,
          },
        };
        break;
      }
      case 'ic_events_categories_18': {
        children = renderPath(2);
        break;
      }
      case 'ic_chevron_square_12': {
        children = renderPath(2);
        break;
      }
      case 'ic_chevron_12': {
        children = renderPath(2);
        break;
      }
      case 'ic_all_filtered_100': {
        children = renderPath(6);
        break;
      }
      case 'ic_status_bullet': {
        children = renderPath(2);
        sxValues = {
          [`&.icon-ic_status_bullet .path1:before`]: {
            color: fontColor,
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_boolean': {
        sxValues = {
          [`&.icon-ic_boolean:before`]: {
            color: fontColor,
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_advance_filter1': {
        sxValues = {
          [`&.icon-ic_advance_filter1:before`]: {
            color: fontColor,
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_calendar_72': {
        children = renderPath(16);
        // sxValues = {
        //   [`&.icon-ic_status_bullet .path1:before`]: {
        //     color: fontColor,
        //   },
        //   ...sxValues,
        // };
        break;
      }
      case 'ic_sort_20_rounded_asc':
      case 'ic_sort_20_rounded_desc': {
        children = renderPath(2);
        break;
      }
      case 'ic_nothing_to_show_72': {
        children = renderPath(4);
        break;
      }
      case 'ic_connected_18_enabled': {
        sxValues = {
          [`&.cg-app-icon:before`]: {
            color: fontColor,
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_empty_100': {
        children = renderPath(21);
        break;
      }
      case 'ic_question_mark': {
        children = renderPath(3);
        break;
      }
      case 'ic_something_wrong_40': {
        children = renderPath(4);
        break;
      }
      case 'ic_team_profile_80': {
        children = renderPath(8);
        // sxValues = {
        //   [`&.icon-ic_status_bullet .path1:before`]: {
        //     color: fontColor,
        //   },
        //   ...sxValues,
        // };
        break;
      }
      case 'ic_google': {
        children = renderPath(4);
        break;
      }
      case 'conditional_format': {
        children = renderPath(4);
        break;
      }
      case 'ic_connected_18_failed': {
        sxValues = {
          [`&.icon-ic_connected_18_failed:before`]: {
            color: fontColor,
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_sort_ascend_20': {
        children = renderPath(2);
      }
      case 'ic_sort_descend_20': {
        children = renderPath(2);
      }
      case 'ic_pivot_create_72': {
        children = renderPath(16);
      }
      case 'ic_export_16': {
        sxValues = {
          [`&.cg-app-icon:before`]: {
            color: fontColor + ' !important',
          },
          ...sxValues,
        };
        break;
      }
      case 'ic_color_setting': {
        children = renderPath(3);
        break;
      }
      case 'ic_table_contents': {
        children = renderPath(32);
        break;
      }
      case 'ic_table_metrics': {
        children = renderPath(32);
        break;
      }
      case 'ic_table_grouping': {
        children = renderPath(32);
        break;
      }
    }

    const parsedSx = {
      fontSize,
      color: fontColor,
      ...sxValues,
    };

    return (
      <StyledBox
        ref={ref}
        component="i"
        className={clsx(CLASSNAME_ICON_CIGRO, className, 'icon-' + name)}
        sx={parsedSx}
        {...boxProps}
        //
      >
        {children}
      </StyledBox>
    );
  },
);

// @ts-ignore displayName
AppIcon.displayName = 'forwardedRef(AppIcon)';

export default AppIcon;
