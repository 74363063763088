import type { LexicalEditor } from 'lexical';

import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $isElementNode,
} from 'lexical';

function $getRootLastElementChild() {
  const rootNode = $getRoot();

  const lastChild = rootNode.getLastChild();

  if ($isElementNode(lastChild)) {
    return lastChild;
  }

  // create empty <p/> node
  const p = $createParagraphNode();
  rootNode.append(p);
  return p;
}

const $createFxEditorStateSetter =
  (defaultFx?: string) => (editor: LexicalEditor) => {
    const currentEditorValue = editor
      .getEditorState()
      .read(() => $getRoot().getTextContent());
    // NOTE: skip when the component update itself
    if (currentEditorValue === defaultFx) return;
    // NOTE: replace the whole content when the value is triggered by its parent
    editor.update(() => {
      const rootNode = $getRoot();
      const newP = $createParagraphNode().append($createTextNode(defaultFx));
      // NOTE: has only 1 child, simply replace it
      if (rootNode.getChildrenSize() === 1) {
        rootNode.getFirstChild()?.replace(newP).selectEnd();
        return;
      }
      // NOTE: has multiple child, remove all, then add only 1 element node as a child
      for (const childNode of rootNode.getChildren()) {
        childNode.remove(true);
      }
      rootNode.append(newP).selectEnd();
    }, {});

    return editor.getEditorState();
  };

const LexicalUtils = {
  $createFxEditorStateSetter,
  $getRootLastElementChild,
};

export default LexicalUtils;
