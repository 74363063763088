import lodashGet from 'lodash/get';
import lodashIsArray from 'lodash/isArray';
import invariant from 'tiny-invariant';
import { z, ZodSchema } from 'zod';

import { testFullTextSearch } from './string';
import { FlattenObject } from './type';

const isDev = process.env.NODE_ENV === 'development';

export function serializeData<T>(data: T | null, schema: ZodSchema): T | null {
  // if (!isDev) return data;
  if (data == null || lodashIsArray(data)) return null;
  const result = schema.safeParse(data);
  if (!result.success) {
    console.error(result.error);
    throw result.error;
  }
  return result.data;
}

export function serializeArrayData<T>(data: T[], schema: ZodSchema): T[] {
  // if (!isDev) return data;
  if (!lodashIsArray(data)) return [];
  const list = data.reduce<T[]>((cummulative, curr) => {
    const result = schema.safeParse(curr);
    if (result.success) {
      cummulative.push(result.data);
    } else {
      console.error(result.error);
      // throw result.error;
    }
    return cummulative;
  }, []);

  return list;
}

export function makeSafeSchema<T extends ZodSchema>(
  zodSchema: T,
  fallback: T['_output'],
) {
  if (fallback == null) {
    invariant(false, 'fallback cannot be null or undefined.');
  }
  return zodSchema
    .nullable()
    .optional()
    .transform((val) => (val == null ? fallback : val));
}

// export function makeSafeArraySchema<T extends ZodSchema>(
//   zodSchema: T,
//   fallback: T['_output'][],
// ) {
//   return z
//     .array(z.unknown()) // `z.unknown()` more relevant than `z.any()` and avoid linting issue with any
//     .transform((items) =>
//       items?.filter(
//         (item): item is z.infer<T> => zodSchema.safeParse(item).success,
//         // `item is z.infer<T>` prevents the output type to be `unknown`. Ok to cast the type as it's been validated
//       ),
//     )
//     .nullable()
//     .optional()
//     .transform((val) => val ?? fallback);
// }

/**
 * @deprecated
 * @param columns
 * @param getProperty
 * @param searchKeyword
 * @returns
 */
export function getFilteredData<T>(
  columns: Array<T>,
  getProperty: (col: T) => string,
  searchKeyword: string,
) {
  if (searchKeyword) {
    return columns.filter((col) => {
      return testFullTextSearch(getProperty(col), searchKeyword);
    });
  }

  return columns;
}

export const filterByProperties = <T>(
  items: T[],
  properties: Array<FlattenObject<T>>,
  searchKeyword: string,
): T[] => {
  if (!searchKeyword) return items;

  return items.filter((item) =>
    properties.some((property) =>
      testFullTextSearch(String(lodashGet(item, property)), searchKeyword),
    ),
  );
};

// Filter pipeline stage function type
type FilterPipelineStage<T> = (items: T[]) => T[];

// Higher-order function to combine multiple filter pipeline stages
export const combineFilterPipelines = <T>(
  ...stages: FilterPipelineStage<T>[]
): FilterPipelineStage<T> => {
  return (items: T[]) => stages.reduce((result, stage) => stage(result), items);
};

/**
 * Moves items from the source array to the target array based on the provided predicate function.
 * This function directly mutates both the source and target arrays.
 * @deprecated use `moveItems` instead
 * @template T - The type of items in the arrays.
 * @param {T[]} sourceArray - The array from which items will be moved. This array will be directly mutated.
 * @param {T[]} targetArray - The array to which items will be moved. This array will be directly mutated.
 * @param {(item: T) => boolean} predicate - A function that tests each item in the source array.
 * If the predicate returns true, the item is moved to the target array.
 *
 * @example
 * const source = [1, 2, 3, 4, 5];
 * const target = [];
 * const isEven = (num) => num % 2 === 0;
 * moveItems(source, target, isEven);
 * console.log(source); // [1, 3, 5]
 * console.log(target); // [2, 4]
 */
export function dangerouslyMoveItems<T>(
  sourceArray: T[],
  targetArray: T[],
  predicate: (item: T) => boolean,
): void {
  // Use filter to keep items that do not match the predicate in the sourceArray
  const remainingItems = sourceArray.filter((item) => {
    if (predicate(item)) {
      targetArray.push(item); // Move the item to the targetArray if predicate is true
      return false; // Remove the item from the sourceArray
    }
    return true; // Keep the item in the sourceArray
  });

  // Update the sourceArray with the remaining items
  sourceArray.length = 0; // Clear the original array
  sourceArray.push(...remainingItems); // Add remaining items back to the original array
}

export function moveItems<Source, Target>(
  from: Source[],
  to: Target[],
  predicate: (item: Source) => boolean,
  transform: (item: Source) => Target,
): { source: Source[]; target: Target[] } {
  const clonedSourceArray = [...from];
  const clonedTargetArray = [...to];
  // Use filter to keep items that do not match the predicate in the sourceArray
  const remainingItems = clonedSourceArray.filter((item) => {
    if (predicate(item)) {
      clonedTargetArray.push(transform(item)); // Move the item to the targetArray if predicate is true
      return false; // Remove the item from the sourceArray
    }
    return true; // Keep the item in the sourceArray
  });

  // Update the sourceArray with the remaining items
  clonedSourceArray.length = 0; // Clear the original array
  clonedSourceArray.push(...remainingItems); // Add remaining items back to the original array
  return {
    source: clonedSourceArray,
    target: clonedTargetArray,
  };
}

/**
 * Interchanges elements from two lists in an alternating manner.
 * If one list is longer, remaining elements are appended at the end.
 *
 * @template T The type of elements in the lists.
 * @param {T[]} list1 The first list of elements.
 * @param {T[]} list2 The second list of elements.
 * @returns {T[]} A new list with elements interchanged from both lists.
 */

function mergeAlternately<T>(list1: T[], list2: T[]): T[] {
  const maxLength = Math.max(list1.length, list2.length);
  const result: T[] = [];

  for (let i = 0; i < maxLength; i++) {
    if (i < list1.length) {
      result.push(list1[i]);
    }
    if (i < list2.length) {
      result.push(list2[i]);
    }
  }

  return result;
}

export const ArrayUtils = {
  mergeAlternately,
};
