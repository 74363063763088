import type {
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextModeType,
} from 'lexical';

import { addClassNamesToElement } from '@lexical/utils';
import { $applyNodeReplacement, TextNode } from 'lexical';

import {
  MODEL_PROPERTY_ALIAS_ATTR,
  MODEL_PROPERTY_COLUMN_ID_ATTR,
} from '../lexical-constants';

const LEFT_CURLY_BRACES = '{';
const RIGHT_CURLY_BRACES = '}';

type SerializedModelPropertyNode = Spread<
  { columnId: string; alias?: string },
  SerializedTextNode
>;

export class ModelPropertyNode extends TextNode {
  static getType(): string {
    return 'model-property';
  }

  static clone(node: ModelPropertyNode): ModelPropertyNode {
    return new ModelPropertyNode(
      node.__text,
      {
        columnId: node.__columnId,
        alias: node.__alias,
      },
      node.__key,
    );
  }

  static importJSON(
    serializedNode: SerializedModelPropertyNode,
  ): ModelPropertyNode {
    const node = $createModelPropertyNode(serializedNode.text, {
      columnId: serializedNode.columnId,
      alias: serializedNode.alias,
    });
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  private __columnId: string;
  private __alias?: string;

  constructor(
    text: string,
    config: {
      columnId: string;
      alias?: string;
    },
    key?: NodeKey,
  ) {
    if (config.alias)
      super(`${LEFT_CURLY_BRACES}${config.alias}${RIGHT_CURLY_BRACES}`, key);
    else super(text, key);
    this.__columnId = config.columnId;
    this.__alias = config.alias;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);

    if (config.theme.modelProperty)
      addClassNamesToElement(element, config.theme.modelProperty);

    // element.setAttribute(MODEL_PROPERTY_ALIAS_ATTR, this.getAlias());
    // element.setAttribute(MODEL_PROPERTY_COLUMN_ID_ATTR, this.getPropertyName());

    return element;
  }

  // updateDOM(
  //   prevNode: ModelPropertyNode,
  //   dom: HTMLElement,
  //   config: EditorConfig,
  // ): boolean {
  //   const update = super.updateDOM(prevNode, dom, config);
  //   // const prevDataAlias = prevNode.getAlias();
  //   // const nextDataAlias = this.getAlias();
  //   // if (prevDataAlias !== nextDataAlias) {
  //   //   dom.setAttribute(MODEL_PROPERTY_ALIAS_ATTR, nextDataAlias);
  //   // }
  //   // const prevDataColumnId = prevNode.getPropertyName();
  //   // const nextDataColumnId = this.getPropertyName();
  //   // if (prevDataColumnId !== nextDataColumnId) {
  //   //   dom.setAttribute(MODEL_PROPERTY_COLUMN_ID_ATTR, nextDataColumnId);
  //   // }
  //   return update;
  // }

  exportJSON(): SerializedModelPropertyNode {
    return {
      ...super.exportJSON(),
      columnId: this.getPropertyName(),
      alias: this.getAlias(),
      type: 'model-property',
    };
  }

  isTextEntity(): true {
    return true;
  }

  // // force this node in 'token' mode \\
  // isSimpleText(): false {
  //   return false;
  // }

  // isToken(): true {
  //   return true;
  // }

  // getMode(): TextModeType {
  //   return 'token';
  // }

  // setMode(_: TextModeType): this {
  //   return super.setMode('token');
  // }
  // //\\

  canInsertTextBefore(): false {
    return false;
  }

  canInsertTextAfter(): false {
    return false;
  }

  canHaveFormat(): false {
    return false;
  }

  isUnmergeable(): true {
    return true;
  }

  // NOTE: custom methods

  getPropertyName(): string {
    const readable = this.getLatest();
    return readable.__columnId;
  }

  getAlias(): string {
    const readable = this.getLatest();
    return readable.__alias || readable.__columnId;
  }

  getPrintableText(): string {
    return `${LEFT_CURLY_BRACES}${this.getPropertyName()}${RIGHT_CURLY_BRACES}`;
  }
}

/**
 * Generates a ModelPropertyNode, which is a string following the format of a {property_name}, eg. {utm_campaign}
 * @param text - The text used inside the ModelPropertyNode.
 * @returns - The ModelPropertyNode with the embedded text.
 */
export function $createModelPropertyNode(
  text: string,
  config: {
    columnId: string;
    alias?: string;
  },
): ModelPropertyNode {
  const modelPropertyNode = new ModelPropertyNode(text, config);
  return $applyNodeReplacement(modelPropertyNode);
}

/**
 * Determines if node is a ModelPropertyNode.
 * @param node - The node to be checked.
 * @returns true if node is a ModelPropertyNode, false otherwise.
 */
export function $isModelPropertyNode(
  node: LexicalNode | null | undefined,
): node is ModelPropertyNode {
  return node instanceof ModelPropertyNode;
}
