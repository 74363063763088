import type { DataModelConfig } from '@/models/widget';

import invariant from 'tiny-invariant';

import { DatasetDetailPayload } from '@/feature-report/types';
import {
  DuplicateDataModelPayload,
  TransformationDataModels,
  zTransformationDataModels,
} from '@/feature-transformation/model';
import { NO_PERMISSION_ACCESS_TEAM_API_ERROR } from '@/initializers/AppErrorBoundary';
import {
  DataModelQuery,
  DataModelsDto,
  DataModelsLogs,
  DataModelsRelatedItem,
  DataModelType,
  DatasetType,
  DownstreamDataModel,
  zDataModelsLogs,
  zDataModelsSchema,
} from '@/models/data-model';
import { serializeArrayData, serializeData } from '@/utils/data';
import { normalizeDatasetId } from '@/utils/dataset-id';
import { isUuid } from '@/utils/string';

import {
  ApiAdapterRequestHeaders,
  BaseApiAdapterClass,
  RequestManager,
} from './__base';
import { clientHttp, serverHttp } from './axios';
import { dataModelsURL } from './url-string';

class DataModelsApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  private invariantUUID(teamId: string) {
    invariant(teamId, 'no team id provided');

    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }
  }

  private clone() {
    return new DataModelsApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(headers: ApiAdapterRequestHeaders) {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  async getAll(
    teamId: string,
    datasetType: DatasetType | 'ALL' = 'ALL',
  ): Promise<DataModelsDto[]> {
    this.invariantUUID(teamId);
    const queryStr = new URLSearchParams({
      dataset_type: datasetType,
    });
    const dataModelsList = await this.get<DataModelsDto[]>(
      dataModelsURL.getAll(teamId) + '?' + queryStr.toString(),
    );

    if (dataModelsList == null) return [];

    return serializeArrayData(dataModelsList, zDataModelsSchema);
  }

  async getDataModelLogs(
    teamId: string,
    datasetId: string,
    dataModelId: string,
    offset: number,
    size = 20,
  ) {
    // this.invariantUUID(teamId);
    // this.invariantUUID(datasetId);
    // this.invariantUUID(dataModelId);

    const queryStr = new URLSearchParams({
      offset: offset.toString(),
      size: size.toString(),
    });

    const logs = await this.get<DataModelsLogs>(
      dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId) +
        '/logs?' +
        queryStr.toString(),
    );

    return serializeData(logs, zDataModelsLogs);
  }

  async getRelatedItems(
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) {
    // this.invariantUUID(teamId);
    // this.invariantUUID(datasetId);
    // this.invariantUUID(dataModelId);

    const relatedItems = await this.get<Array<DataModelsRelatedItem>>(
      dataModelsURL.getRelatedItems(teamId, datasetId, dataModelId),
    );

    if (!relatedItems) return [];
    return relatedItems ?? [];
    // return serializeArrayData(relatedItems, zDataModelsRelatedItem);
  }

  async getById(teamId: string, datasetId: string, dataModelId: string) {
    const dataModelDetail = await this.get<TransformationDataModels>(
      dataModelsURL.getDataModelsDetail(
        teamId,
        normalizeDatasetId(datasetId),
        dataModelId,
      ),
    );

    return serializeData(dataModelDetail, zTransformationDataModels);
  }

  async deleteDataModels(
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) {
    await this.request(
      dataModelsURL.getDataModelsDetail(teamId, datasetId, dataModelId),
      'delete',
    );
  }

  async getDownstreamsModels(
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) {
    return await this.get<DownstreamDataModel[]>(
      dataModelsURL.getDownstreams(teamId, datasetId, dataModelId),
    );
  }

  async getDataModelQuery({
    teamId,
    datasetId,
    dataModelId,
  }: {
    teamId: string;
    datasetId: string;
    dataModelId: string;
  }) {
    return await this.get<DataModelQuery>(
      dataModelsURL.getDataModelQuery(teamId, datasetId, dataModelId),
    );
  }

  async putDatasetById(
    teamId: string,
    datasetId: string,
    payload: DatasetDetailPayload,
  ) {
    return await this.request<DataModelsDto['dataset'], DatasetDetailPayload>(
      dataModelsURL.getById(teamId, datasetId),
      'put',
      payload,
    );
  }

  async getDataModelByIdAPI(
    teamId: string,
    datasetId: string,
    dataModelType: DataModelType = 'TABLE',
  ) {
    const queryStr = new URLSearchParams({
      data_model_type: dataModelType,
    });
    const data = await this.get<DataModelsDto>(
      dataModelsURL.getById(teamId, datasetId) + '?' + queryStr.toString(),
    );
    if (!data) return null;
    return serializeData<DataModelsDto>(data, zDataModelsSchema);
  }

  async getDataModelsAPI(teamId: string, datasetType: string) {
    const queryStr = new URLSearchParams({
      dataset_type: datasetType,
    });
    return (
      (await this.get<DataModelsDto[]>(
        dataModelsURL.getAll(teamId) + '?' + queryStr.toString(),
      )) ?? []
    );
  }

  async duplicateDataModels(
    teamId: string,
    datasetId: string,
    dataModelId: string,
    payload: DuplicateDataModelPayload,
  ) {
    return await this.request<
      TransformationDataModels,
      DuplicateDataModelPayload
    >(
      dataModelsURL.duplicateDataModels(teamId, datasetId, dataModelId),
      'post',
      payload,
    );
  }

  /**
   *
   * @deprecated - API has been deprecated
   */
  async getConfig({
    teamId,
    dataModelId,
    datasetId,
  }: {
    teamId: string;
    dataModelId: string;
    datasetId: string;
  }) {
    return {
      id: null,
      dataset_id: 'string',
      config: null,
    };
    // return await this.get<{
    //   id: string | null | undefined;
    //   dataset_id: string;
    //   config: DataModelConfig | null | undefined;
    // }>(dataModelsURL.getConfig(teamId, datasetId, dataModelId));
  }

  async createConfig({
    teamId,
    dataModelId,
    datasetId,
    config,
  }: {
    teamId: string;
    dataModelId: string;
    datasetId: string;
    config: DataModelConfig;
  }) {
    const url = dataModelsURL.getConfig(teamId, datasetId, dataModelId);

    const payload = {
      dataset_id: datasetId,
      config,
    };

    return await this.request<
      {
        dataset_id: string;
        config: DataModelConfig | null | undefined;
      },
      {
        dataset_id: string;
        config: DataModelConfig;
      }
    >(url, 'post', payload);
  }

  async editConfig({
    teamId,
    dataModelId,
    datasetId,
    config,
  }: {
    teamId: string;
    dataModelId: string;
    datasetId: string;
    config: DataModelConfig;
  }) {
    const url = dataModelsURL.getConfig(teamId, datasetId, dataModelId);

    const payload = {
      dataset_id: datasetId,
      config,
    };

    return await this.request<
      {
        dataset_id: string;
        config: DataModelConfig | null | undefined;
      },
      {
        dataset_id: string;
        config: DataModelConfig;
      }
    >(url, 'put', payload);
  }
}

/**
 * @deprecated
 */
export const DataModelsClientApiAdapter = Object.freeze(
  new DataModelsApiAdapterClass(clientHttp),
);
/**
 * @deprecated
 */
export const DataModelsServerApiAdapter = Object.freeze(
  new DataModelsApiAdapterClass(serverHttp),
);

export const DataModelsApi = {
  onBrowser: () => new DataModelsApiAdapterClass(clientHttp),
  onServer: () => new DataModelsApiAdapterClass(serverHttp),
};
