import type { RequestManager } from '@/adapters/api/__base';
import type { WidgetDto } from '@/models/widget';

import { BaseApiAdapterClass } from '@/adapters/api/__base';
import { NO_PERMISSION_ACCESS_TEAM_API_ERROR } from '@/initializers/AppErrorBoundary';
import { WidgetDtoSchema } from '@/models/widget';
import { serializeArrayData, serializeData } from '@/utils/data';
import { isUuid } from '@/utils/string';

import {
  CreateMetricPayload,
  GoalMetric,
  zGoalMetric,
} from '../../models/view/goal';
import { bigNumberHttp, clientHttp, serverHttp } from './axios';

class GoalMetricApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  async create(
    teamId: string,
    dataModelId: string,
    datasetId: string,
    payload: CreateMetricPayload,
  ): Promise<GoalMetric | null> {
    const url = `/teams/${teamId}/goal-metrics`;

    const data = await this.request<GoalMetric, CreateMetricPayload>(
      url,
      'post',
      payload,
      { data_model_id: dataModelId, dataset_id: datasetId },
    );

    return serializeData(data, zGoalMetric);
  }

  async update(
    teamId: string,
    metricId: string,
    payload: CreateMetricPayload,
  ): Promise<GoalMetric | null> {
    const url = `/teams/${teamId}/goal-metrics/${metricId}`;

    const data = await this.request<GoalMetric, CreateMetricPayload>(
      url,
      'put',
      payload,
    );

    return serializeData(data, zGoalMetric);
  }

  async delete(teamId: string, metricId: string): Promise<unknown> {
    const url = `/teams/${teamId}/goal-metrics/${metricId}`;

    const data = await this.request<unknown>(url, 'delete');

    return data;
  }

  async getAll(
    teamId: string,
    dataModelId: string,
    datasetId: string,
  ): Promise<GoalMetric[]> {
    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    const data = await this.get<GoalMetric[]>(`/teams/${teamId}/goal-metrics`, {
      data_model_id: dataModelId,
      dataset_id: datasetId,
    });

    return serializeArrayData(data ?? [], zGoalMetric);
  }

  async getDetail(
    teamId: string,
    dataModelId: string,
    datasetId: string,
    metricId: string,
  ): Promise<GoalMetric | null> {
    // // NOTE: check valid teamId
    // if (!isUuid(teamId)) {
    //   throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    // }

    const data = await this.get<GoalMetric | null>(
      `/teams/${teamId}/goal-metrics/${metricId}`,
      { data_model_id: dataModelId, dataset_id: datasetId },
    );

    if (!data) return null;

    return serializeData(data, zGoalMetric);
  }
}

const GoalMetricApi = {
  onBrowser: () => new GoalMetricApiAdapterClass(clientHttp),
  // onBrowserBigNumber: () => new GoalMetricApiAdapterClass(bigNumberHttp),
  onServer: () => new GoalMetricApiAdapterClass(serverHttp),
};

export default GoalMetricApi;
