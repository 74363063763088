import type {
  AggregatedFilter,
  CompositeFilter,
  Filter,
  ReportRequestBody,
} from '@/feature-report/types';
import type { CustomColumnsConfigMap } from '@/models/common';

import {
  COLUMN_ID_REGEX,
  FX_COLUMN_ID_REGEX,
  SUPPORTED_FNS_REGEX,
} from '@cigro/formula/shared';
import {
  createFxReferenceMap,
  isAggFx,
  removeFormulaAnnotation,
} from '@cigro/formula/utils';

import {
  type AggregateType,
  type DateFormatFn,
  zDateFormatFn,
  zNonAggregationFn,
} from '@/models/view';

import { MAX_NUM_OF_RECORDS } from '../table-view/constant';

function countFxReferences({
  targetFxColId,
  columnsConfig,
}: {
  targetFxColId: string;
  columnsConfig: CustomColumnsConfigMap;
}): number {
  let referencesCount = 0;

  const targetColId = removeFormulaAnnotation(targetFxColId);

  for (const [colId, colConf] of Object.entries(columnsConfig)) {
    if (colId === targetFxColId) continue;

    if (!colConf.formula) {
      continue;
    }

    const refsMap = createFxReferenceMap({
      targetFx: colConf.formula,
      columnsConfig,
    });

    if (refsMap[targetColId]) referencesCount += 1;
  }

  return referencesCount;
}

type FilterCb = (_filterItem: Filter) => void;

function forEachFilterItem(
  filters: Array<Filter | AggregatedFilter | CompositeFilter>,
  iteratee: FilterCb,
) {
  for (const filter of filters) {
    if ('filters' in filter) {
      forEachFilterItem(filter.filters, iteratee);
    } else {
      iteratee(filter);
    }
  }
}

// exclude all of theses special characters, but allow dashes - and underscores _
const ANY_CHARS_DASHES__ = `[^\\s@#\\$%\\^&\\*\\(\\)]+`;

// .e.g: sum(query_cost) or sum(formula(fx))
const FN_COL_ID_REG_TXT = `(${SUPPORTED_FNS_REGEX})\\((${COLUMN_ID_REGEX}|(${FX_COLUMN_ID_REGEX})|(${ANY_CHARS_DASHES__}))\\)`;
const DATE_FN_COL_ID_REG_TXT = `(date|week|month|quarter|year)\\((${COLUMN_ID_REGEX}|(${FX_COLUMN_ID_REGEX})|(${ANY_CHARS_DASHES__}))\\)`;

/**
 * Extracts the original column id when .e.g. sum(query_cost) or sum(formula(fx)) or cummulate(sum(query_cost), date(reported_at))
 *
 * **NOTE:** Return`null`if the`maybeColId`has an invalid format
 * @param maybeColId {string}
 * @returns For .e.g: `query_cost` or `formula(fx)` or `{colId: query_cost, aggFn: sum}`
 */
function extractColumnId(maybeColId: string):
  | {
      colId: string;
      aggFn: Lowercase<AggregateType>;
    }
  | string
  | null {
  // .e.g: sum(...)
  const aggColIdRegEx = new RegExp(`^(${FN_COL_ID_REG_TXT})$`, 'i');

  const aggMatchArr = aggColIdRegEx.exec(maybeColId);

  if (aggMatchArr?.length) {
    const [, , fn, columnId] = aggMatchArr;
    return {
      aggFn: fn.toLowerCase() as Lowercase<AggregateType>,
      colId: columnId,
    };
  }

  // .e.g: date(...)
  const dateAggColIdRegEx = new RegExp(`^(${DATE_FN_COL_ID_REG_TXT})$`, 'i');

  const dateAggMatchArr = dateAggColIdRegEx.exec(maybeColId);

  if (dateAggMatchArr?.length) {
    const [, , fn, columnId] = dateAggMatchArr;
    return {
      aggFn: fn.toLowerCase() as Lowercase<AggregateType>,
      colId: columnId,
    };
  }

  // .e.g: cummulative(sum(column_id), date(date_column_id))
  const cummulativeColIdRegEx = new RegExp(
    `cummulate\\((${FN_COL_ID_REG_TXT}|${FX_COLUMN_ID_REGEX}),(${DATE_FN_COL_ID_REG_TXT})\\)`,
    'i',
  );

  const cummulateAggMatchArr = cummulativeColIdRegEx.exec(maybeColId);

  if (cummulateAggMatchArr?.length) {
    /**
     * If want to catch date column ids, try this way, but be careful about the `if tree` order!!!
     * ```
     * // 9: "date(date_column_id)"
     * // 10: "date_column_id"
     * // const dateWithFn = cummulateAggMatchArr[9];
     * // const dateColId = cummulateAggMatchArr[10];
     * ```
     */

    /**
     * Case: cummulate(sum(formula(id)), date(date_column_id))
     */
    const aggFn = cummulateAggMatchArr[2];
    const idWithAggFn = cummulateAggMatchArr[3];
    if (aggFn && idWithAggFn) {
      return {
        // aggFn: ['cummulate', aggFn], // actually there are two agg fns in this case!
        aggFn: 'cummulate',
        colId: idWithAggFn,
      };
    }

    /**
     * Case: cummulate(formula(id), date(date_column_id))
     */
    const aggFxColId = cummulateAggMatchArr[1];
    if (aggFxColId) {
      return {
        aggFn: 'cummulate',
        colId: aggFxColId,
      };
    }
  }

  // fx column id
  const fxColIdRegex = new RegExp(`^(${FX_COLUMN_ID_REGEX})$`, 'i');

  if (fxColIdRegex.test(maybeColId)) return maybeColId;

  // regular column id
  const colIdRegex = new RegExp(`^(${COLUMN_ID_REGEX})$`, 'i');

  if (colIdRegex.test(maybeColId)) return maybeColId;

  /**
   * Handle backward compatible GROUPING_FORMULA
   */
  const backwardGroupingFormulaRegex = new RegExp(
    `^formula\\((formula\\((.*?)\\))\\)$`,
    'i',
  );

  const groupingFormulaRegex = backwardGroupingFormulaRegex.exec(maybeColId);

  if (groupingFormulaRegex?.length)
    return {
      aggFn: 'formula',
      colId: groupingFormulaRegex[1],
    };

  // invalid format
  return null;
}

function initEmpty({
  teamId,
  dataModelId,
  datasetId,
  viewId,
  pagination,
  responseType,
  firstDayOfWeek,
}: {
  teamId: string;
  dataModelId: string;
  datasetId?: string;
  viewId?: string | null;
  pagination?: ReportRequestBody['data']['loader']['pagination'];
  responseType?: ReportRequestBody['responseType'];
  firstDayOfWeek?: ReportRequestBody['data']['loader']['first_day_of_week'];
}) {
  const req: ReportRequestBody = {
    responseType: responseType,
    apiVersion: 'DBT_1',
    data: {
      teamId,
      loader: {
        viewId,
        timezone: 'UTC',
        pagination: pagination ?? {
          page: 1,
          perPage: MAX_NUM_OF_RECORDS,
        },
        //
        joins: [],
        filters: [],
        groupBy: [],
        sort: [],
        first_day_of_week: firstDayOfWeek,
      },
      source: {
        datasetId: datasetId || teamId,
        columns: [],
        name: dataModelId,
      },
    },
  };

  return req;
}

type AggType = AggregateType | Lowercase<AggregateType> | DateFormatFn;

/**
 * Parse to report API's aggregation column syntax
 *
 * If `aggFn` = `AGG` or `FORMULA` -> it will ignore & return the original column ID
 * @param {string} columnId
 * @param {AggType} aggFn
 * @param {number} n The number of calls at which `func` is no longer invoked.
 * @returns
 */
function wrapColumnWithFn(columnId: string, aggFn: AggType): string {
  // ig nore FX Function if FX function -> columnId
  if (aggFn.toLowerCase() === 'agg') return columnId;
  if (aggFn.toLowerCase() === 'formula') return columnId;
  //
  return `${aggFn.toLowerCase()}(${columnId})`;
}

function checkAggFxColumn({
  columnId,
  columnsConfig,
}: {
  columnId: string;
  columnsConfig?: CustomColumnsConfigMap | null;
}): boolean {
  return Boolean(
    columnsConfig?.[columnId] &&
      isAggFx(columnsConfig?.[columnId]?.formula, columnsConfig),
  );
}

/**
 * Check validity if column ID, returns`true`if it is valid
 * @param maybeColId
 * @returns
 */
function isColumnIdValid(maybeColId: string): boolean {
  return extractColumnId(maybeColId) != null;
}

function isAggregationFn(aggFn: string): boolean {
  const result = zNonAggregationFn.safeParse(aggFn.toUpperCase());
  if (result.success) return false;
  const dateResult = zDateFormatFn.safeParse(aggFn.toLowerCase());
  if (dateResult.success) return false;
  return true;
}

// namespace
const RequestBodyUtils = {
  initEmpty,
  isAggregationFn,
  isColumnIdValid,
  extractColumnId,
  wrapColumnWithFn,
  countFxReferences,
  forEachFilterItem,
  checkAggFxColumn,
};

export default RequestBodyUtils;
