import type { CreateAxiosDefaults } from 'axios';

import axios from 'axios';

import { registerApiErrorParserResponseInterceptor } from '@/adapters/api/axios/utils';

const TIMEOUT_MS = 1000 * 60;

const CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.NEXT_PUBLIC_CIGRO_PROVIDER_API,
  timeout: TIMEOUT_MS,
  headers: { accept: 'application/json' },
};

// NOTE: Create axios instance.
const CigroDataAxios = axios.create(CONFIG);

// TODO: setup cookie header interceptors

//
registerApiErrorParserResponseInterceptor(CigroDataAxios);

export default CigroDataAxios;
