import Dialog, { dialogClasses, type DialogProps } from '@mui/material/Dialog';
import DialogTitle, {
  dialogTitleClasses,
  type DialogTitleProps,
} from '@mui/material/DialogTitle';
import { modalClasses } from '@mui/material/Modal';
import { styled, SxProps, Theme } from '@mui/material/styles';

import AppButton from '@components/Button';
import AppIcon from '@components/Icon';

// ======= DIALOG TITLE
const StyledCigroDialogTitle = styled(DialogTitle)(({ sx, theme }) => ({
  [`&.${dialogTitleClasses.root}`]: {
    position: 'relative',
    padding: '16px 16px 12px 16px',
    ...(sx ? theme.unstable_sx(sx) : {}),
  },
}));

export const CloseButton: React.FC<{
  sx?: SxProps<Theme>;
  onClose: () => void;
}> = ({ sx, onClose }) => (
  <AppButton
    variant="icon-only"
    sx={[
      {
        position: 'absolute',
        top: '12px',
        right: '12px',
        borderRadius: '2px',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    onClick={onClose}
    //
  >
    <AppIcon
      name="close-line"
      size={16}
      color={(t) => t.palette.neutralV2[2]}
    />
  </AppButton>
);

export function AppDialogTitle({
  children,
  onClose,
  ...props
}: DialogTitleProps & {
  onClose: () => void;
}) {
  return (
    <>
      <StyledCigroDialogTitle {...props}>{children}</StyledCigroDialogTitle>
      {/* CLOSE ICON */}
      <CloseButton onClose={onClose} />
    </>
  );
}

const StyledCigroDialog = styled(Dialog)(({}) => ({
  [`& .${modalClasses.backdrop}`]: {
    background: '#20202099',
  },
  [`& .${dialogClasses.paper}`]: {
    borderRadius: '10px',
  },
}));

type AppDialogProps = DialogProps & {
  disableCloseOnBackdropClick?: boolean;
  disableCloseOnEscKeydown?: boolean;
};

export function AppDialog({
  children,
  onClose,
  disableCloseOnBackdropClick = false,
  disableCloseOnEscKeydown = false,
  ...props
}: AppDialogProps) {
  return (
    <StyledCigroDialog
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && disableCloseOnBackdropClick) {
          return;
        }
        if (reason === 'escapeKeyDown' && disableCloseOnEscKeydown) {
          return;
        }
        onClose?.(e, reason);
      }}
      {...props}>
      {children}
    </StyledCigroDialog>
  );
}
