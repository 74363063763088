import React, {
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  Ref,
} from 'react';

import { ButtonTypeMap } from '@mui/base/Button';
import { SxProps, Theme } from '@mui/material';

import StyledButtonV2, { IAppButtonV2 } from './Button.v2';

const buttonSx = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

interface IButtonWithIcons extends PropsWithChildren {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  sx?: SxProps<Theme>;
}

const AppButtonV2 = <
  RootComponentType extends React.ElementType = ButtonTypeMap['defaultComponent'],
>(
  {
    children,
    startIcon,
    endIcon,
    sx,
    ...buttonProps
  }: IButtonWithIcons & IAppButtonV2<RootComponentType>,
  ref: Ref<HTMLButtonElement>,
) => {
  return (
    <StyledButtonV2
      sx={[buttonSx, ...(Array.isArray(sx) ? sx : [sx])]}
      ref={ref}
      {...buttonProps}
      //
    >
      {startIcon}
      {children}
      {endIcon}
    </StyledButtonV2>
  );
};

const ButtonV2 = forwardRef(AppButtonV2);

export type AppButtonV2Props = ComponentProps<typeof AppButtonV2>;

// @ts-ignore
ButtonV2.displayName = 'ButtonV2';

export default ButtonV2;
