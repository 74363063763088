'use client';

import type { SxProps, Theme } from '@mui/material/styles';

import React, { ReactNode } from 'react';

import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import Box from '@mui/material/Box';
import { $getRoot } from 'lexical';

import { CIGRO_SCROLL_BAR_4PX_MIXINS } from '@/constants/mixins';
import { spreadMuiSxes } from '@/utils/styles';

import { FX_COMPONENT_THEME, FX_EDITOR_THEME } from '../../lexical-constants';
import { ModelPropertyNode } from '../model-property-node';
import { useEditorFocus } from './use-editor-focus';

type Props = {
  onChange?: (value: string) => void;
  hasError?: boolean;
  sx?: SxProps<Theme>;
  placeholder?: ReactNode;
  uiPlugins?: React.ReactNode;
};

export const FormulaPlainTextPlugin: React.FC<Props> = ({
  onChange,
  sx,
  placeholder = 'Input your formula here',
  hasError = false,
  uiPlugins,
}) => {
  const focus = useEditorFocus();

  return (
    <Box
      className="CigroContentEditable__Container"
      position="relative"
      display="flex"
      flexDirection="column"
      gap="4px"
      flex={1}
      //
    >
      {/* ON CHANGE NOTIFIER */}
      <OnChangePlugin
        ignoreSelectionChange
        onChange={(editorState) => {
          if (typeof onChange === 'undefined') return;

          const textContent = editorState.read(() => {
            const rootNode = $getRoot();

            const allTextNodes = rootNode.getAllTextNodes();

            /**
             * Return all text nodes from left to right order
             */
            const proccessedText = allTextNodes.reduce<string>(
              (textContent, node) => {
                if (node instanceof ModelPropertyNode) {
                  return textContent + node.getPrintableText();
                }
                return textContent + node.getTextContent();
              },
              '',
            );

            return proccessedText;
          });

          onChange(textContent);
        }}
      />

      <PlainTextPlugin
        contentEditable={
          <Box
            component={ContentEditable}
            spellCheck={false}
            sx={[
              FX_EDITOR_THEME,
              FX_COMPONENT_THEME,
              CIGRO_SCROLL_BAR_4PX_MIXINS,
              focus
                ? {
                    boxShadow: (t) => `0 0 0 1px ${t.palette.primary[100]}`,
                  }
                : {},
              hasError
                ? {
                    boxShadow: (t) =>
                      `0 0 0 1px ${t.palette.semantic.negative100}`,
                  }
                : {},
              ...spreadMuiSxes(sx),
            ]}
          />
        }
        placeholder={
          <Box
            component="span"
            className="CigroContentEditable__Placeholder"
            position="absolute"
            top="8px"
            left="8px"
            sx={{
              pointerEvents: 'none',
              typography: 'body2',
              color: 'neutralV2.3',
            }}
            //
          >
            {placeholder}
          </Box>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />

      {uiPlugins}
    </Box>
  );
};
