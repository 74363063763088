'use client';
import type { LexicalEditor } from 'lexical';
import type { PropsWithChildren } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';

import { CIGRO_THEME, NAMESPACE } from './lexical-constants';
import { ModelPropertyNode } from './lexical-editor';
import LexicalUtils from './lexical-editor/plugins/LexicalUtils';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError: (error: Error, editor: LexicalEditor) => void = (error) => {
  console.error(error);
};

const EDITOR_INITIAL_CONFIG = {
  namespace: NAMESPACE,
  theme: CIGRO_THEME,
  nodes: [ModelPropertyNode],
  onError,
};

export const FxEditorLexicalProvider: React.FC<
  PropsWithChildren<{
    defaultFx?: string;
    readonly?: boolean | undefined;
  }>
> = ({ children, defaultFx, readonly = false }) => {
  return (
    <LexicalComposer
      initialConfig={{
        ...EDITOR_INITIAL_CONFIG,
        editorState: LexicalUtils.$createFxEditorStateSetter(defaultFx),
        editable: !readonly,
      }}
      //
    >
      {children}
    </LexicalComposer>
  );
};
