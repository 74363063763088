import {
  Button as UnstyledButton,
  buttonClasses,
  ButtonProps,
  ButtonTypeMap,
} from '@mui/base/Button';
import { styled, Theme } from '@mui/material/styles';
import lodashMergeWith from 'lodash/mergeWith';

import { spreadMuiSxes } from '@/utils/styles';
import { exhaustiveTypeCheck } from '@/utils/type';

import {
  BUTTON_CLASSNAME_ROOT_ACTIVE,
  BUTTON_CLASSNAME_ROOT_DISABLED,
  BUTTON_CLASSNAME_ROOT_FOCUS,
  BUTTON_CLASSNAME_ROOT_HOVER,
} from './constants';
import { StyleSlots } from './types';

export function getButtonV2Styles({
  hover,
  focus,
  active,
  disabled,
  root: enabled,
}: StyleSlots) {
  return {
    ...enabled,
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: hover,
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      ...focus,
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      ...active,
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      backgroundColor: 'inherit',
      ...disabled,
    },
  };
}

export type IAppButtonV2<
  RootComponentType extends React.ElementType = ButtonTypeMap['defaultComponent'],
> = {
  color?: 'primary' | 'danger' | 'tertiary';
  variant?: 'contain' | 'outline' | 'text';
  size?: 'large' | 'medium' | 'small' | 'smallest';
  rounded?: boolean;
  isLoading?: boolean;
} & ButtonProps<RootComponentType>;

export const getButtonSize = (
  theme: Theme,
  size: IAppButtonV2['size'] = 'small',
) => {
  switch (size) {
    case 'large':
      return {
        padding: '11px 16px',
        ...theme.typography.h4,
      };
    case 'medium':
      return {
        padding: '8px 14px',
        ...theme.typography.h5,
      };
    case 'small':
      return {
        padding: '6px 12px',
        ...theme.typography.small1,
      };
    case 'smallest':
      return {
        padding: '3px 12px',
        ...theme.typography.small1,
      };
    default:
      exhaustiveTypeCheck(size);
  }
};

const getButtonBorderRadius = (
  rounded: IAppButtonV2['rounded'] = false,
  size: IAppButtonV2['size'] = 'small',
) => {
  if (rounded) {
    return {
      borderRadius: '99px',
    };
  }

  switch (size) {
    case 'large':
    case 'medium':
      return {
        borderRadius: '8px',
      };
    case 'small':
      return {
        borderRadius: '6px',
      };
    case 'smallest':
      return {
        borderRadius: '4px',
      };
  }
};

const getButtonContainedColor = (
  theme: Theme,
  color: IAppButtonV2['color'] = 'primary',
) => {
  switch (color) {
    case 'primary':
      return getButtonV2Styles({
        root: {
          color: theme.palette.neutralV2[8],
          backgroundColor: theme.palette.primary[100],
        },
        hover: {
          backgroundColor: theme.palette.primary[90],
        },
        active: {
          backgroundColor: theme.palette.primary[80],
        },
        focus: {
          backgroundColor: theme.palette.primary[100],
          border: `1px solid ${theme.palette.primary[30]}`,
        },
        disabled: {
          backgroundColor: theme.palette.primary[10],
        },
      });
    case 'danger':
      return getButtonV2Styles({
        root: {
          color: theme.palette.neutralV2[8],
          backgroundColor: theme.palette.semantic.negative100,
        },
        hover: {
          backgroundColor: theme.palette.semantic.negative90,
        },
        active: {
          backgroundColor: theme.palette.semantic.negative80,
        },
        focus: {
          backgroundColor: theme.palette.semantic.negative100,
          border: `1px solid ${theme.palette.semantic.negative80}`,
        },
        disabled: {
          backgroundColor: theme.palette.semantic.negative10,
        },
      });
    case 'tertiary':
      return getButtonV2Styles({
        root: {
          color: theme.palette.neutralV2[8],
          backgroundColor: theme.palette.neutralV2[0],
        },
        hover: {
          backgroundColor: theme.palette.neutralV2[1],
        },
        active: {
          backgroundColor: theme.palette.neutralV2[2],
        },
        focus: {
          backgroundColor: theme.palette.neutralV2[0],
          border: `1px solid ${theme.palette.neutralV2[3]}`,
        },
        disabled: {
          backgroundColor: theme.palette.neutralV2[5],
        },
      });
    default:
      exhaustiveTypeCheck(color);
  }
};

const getButtonTextColor = (
  theme: Theme,
  color: IAppButtonV2['color'] = 'primary',
) => {
  switch (color) {
    case 'primary':
      return getButtonV2Styles({
        root: {
          backgroundColor: theme.palette.neutralV2[8],
          color: theme.palette.primary[100],
        },
        hover: {
          backgroundColor: theme.palette.primary[10],
        },
        active: {
          backgroundColor: theme.palette.primary[20],
        },
        focus: {
          border: `1px solid ${theme.palette.primary[70]}`,
        },
        disabled: {
          color: theme.palette.primary[50],
        },
      });
    case 'danger':
      return getButtonV2Styles({
        root: {
          backgroundColor: theme.palette.neutralV2[8],
          color: theme.palette.semantic.negative100,
        },
        hover: {
          backgroundColor: theme.palette.semantic.negative10,
        },
        active: {
          backgroundColor: theme.palette.semantic.negative50,
        },
        focus: {
          border: `1px solid ${theme.palette.semantic.negative90}`,
        },
        disabled: {
          color: theme.palette.semantic.negative50,
        },
      });
    case 'tertiary':
      return getButtonV2Styles({
        root: {
          backgroundColor: theme.palette.neutralV2[8],
          color: theme.palette.neutralV2[0],
        },
        hover: {
          backgroundColor: theme.palette.neutralV2[7],
        },
        active: {
          backgroundColor: theme.palette.neutralV2[6],
        },
        focus: {
          border: `1px solid ${theme.palette.neutralV2[2]}`,
        },
        disabled: {
          color: theme.palette.neutralV2[4],
        },
      });
    default:
      exhaustiveTypeCheck(color);
  }
};

const getButtonOutlinedColor = (
  theme: Theme,
  color: IAppButtonV2['color'] = 'primary',
) => {
  switch (color) {
    case 'primary':
      return getButtonV2Styles({
        root: {
          border: `1px solid ${theme.palette.primary[20]}`,
        },
        focus: {
          border: `1px solid ${theme.palette.primary[70]}`,
        },
      });
    case 'danger':
      return getButtonV2Styles({
        root: {
          border: `1px solid ${theme.palette.semantic.negative50}`,
        },
        focus: {
          border: `1px solid ${theme.palette.semantic.negative90}`,
        },
      });
    case 'tertiary':
      return getButtonV2Styles({
        root: {
          border: `1px solid ${theme.palette.neutralV2[4]}`,
        },
        focus: {
          border: `1px solid ${theme.palette.neutralV2[2]}`,
        },
      });
    default:
      exhaustiveTypeCheck(color);
  }
};

export const getButtonVariant = (
  theme: Theme,
  variant: IAppButtonV2['variant'] = 'text',
  color: IAppButtonV2['color'] = 'primary',
) => {
  switch (variant) {
    case 'contain':
      return getButtonContainedColor(theme, color);
    case 'outline':
      return lodashMergeWith(
        getButtonOutlinedColor(theme, color),
        getButtonTextColor(theme, color),
        (objValue, srcValue) => {
          if (typeof objValue === 'object' || typeof srcValue === 'object') {
            return {
              ...objValue,
              ...srcValue,
            };
          }

          return objValue || srcValue;
        },
      );
    case 'text':
      return getButtonTextColor(theme, color);
    default:
      exhaustiveTypeCheck(variant);
  }
};

const StyledButtonV2 = styled(UnstyledButton, {
  shouldForwardProp: (propName) => {
    if (propName === 'isLoading') return false;
    if (propName === 'rounded') return false;
    if (propName === 'variant') return false;
    if (propName === 'color') return false;
    if (propName === 'size') return false;
    if (propName === 'sx') return false;
    if (propName === 'ownerState') return false;
    return true;
  },
})<IAppButtonV2>((props) => {
  return [
    {
      cursor: 'pointer',
      boxSizing: 'border-box',
      border: '1px solid transparent',
      userSelect: 'none',
      verticalAlign: 'middle',
      appearance: 'none',
      textDecoration: 'none',
      opacity: props.isLoading ? '0.5' : 1,
    },
    getButtonSize(props.theme, props.size),
    getButtonBorderRadius(props.rounded, props.size),
    getButtonVariant(props.theme, props.variant, props.color),
    props.sx ? props.theme.unstable_sx(props.sx) : {},
  ];
});

export default StyledButtonV2;
