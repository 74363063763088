import type {
  AggregateItem,
  AggregateType,
  Column,
  ColumnType,
  DateFormatFn,
  GoalTarget,
  TableFullConfig,
  TimeSeriesPeriod,
  ViewConfig,
  ViewType,
} from '../models/view';
import type { ViewColumn } from '@/feature-report/store';
import type { ColumnName } from '@/feature-report/table-view/_utilsV1/ColumnUtils';

import { isAggFx, isAnalyticalFx } from '@cigro/formula/utils';
import { arrayMove } from '@dnd-kit/sortable';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';

import RequestBodyUtils from '@/feature-report/utils/RequestBodyUtils';

import { ViewConfigPayload } from '../feature-dashboard/widget/api';
import { BOARD_DEFAULT_NAME } from '../feature-report/charts/board/constant';
import { moveItems } from './data';
import { exhaustiveTypeCheck } from './type';

dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);

export const getChartDefaultConfig = (
  type: ViewType,
): ViewConfigPayload['config'] => {
  switch (type) {
    case 'TABLE': {
      return {
        view_type: 'TABLE',
        column_grouping: [],
        column_size: {},
        sort: [],
        fixed_columns: [],
      };
    }
    case 'PIE_CHART':
      return {
        view_type: 'PIE_CHART',
        show_values: false,
        type: 'PIE',
      };
    case 'PIVOT_TABLE':
    case 'GALLERY':
      return {
        view_type: type,
        sort: [],
        image_fit: 'FIT',
        is_grouping_mode: false,
        other_columns: [],
      };
    case 'GOAL':
      return {
        view_type: type,
        target_period: 'MONTHLY',
        goal_type: 'ONE_PERIOD',
        is_cumulative: true,
        visual_type: 'PROGRESS_BAR',
      };
    case 'BAR_AND_LINE_COMBINED_CHART':
      return {
        view_type: 'BAR_AND_LINE_COMBINED_CHART',
        orientation: 'VERTICAL',
        rotate_label: false,
        show_values: false,
        label_size: 6,
        sort: [],
        grouping: {
          columns: [],
          sub_aggregates: [],
        },
      };
    case 'BAR_CHART':
      return {
        view_type: 'BAR_CHART',
        orientation: 'VERTICAL',
        rotate_label: false,
        show_values: false,
        label_size: 6,
      };
    case 'BOARD':
      return {
        view_type: 'BOARD',
        title: BOARD_DEFAULT_NAME,
        time_series: {
          periodicity: 'DAILY' as const,
        },
      };
    case 'LINE_CHART':
      return {
        view_type: 'LINE_CHART',
        time_series: {
          periodicity: 'DAILY' as const,
        },
      };

    default:
      return exhaustiveTypeCheck(type);
  }
};

export function createAggregationColumnName(
  columnName: ColumnName,
  aggregationType: AggregateType,
) {
  return RequestBodyUtils.wrapColumnWithFn(columnName, aggregationType);
}

export const getAppliedVariants = ({
  colorConditionsConfig,
  columnName,
  columnType,
}: {
  colorConditionsConfig: ViewConfig['color_conditions'];
  columnName?: string;
  columnType?: ColumnType;
}) => {
  return colorConditionsConfig
    .map((item) => {
      const sameTypeVariant = item.variants.filter(
        (item) => item.condition.column_type === columnType,
      );

      if (item.affect_on === 'ALL') {
        return sameTypeVariant;
      }
      if (
        item.affect_on === 'EXCEPT' &&
        item.column?.column_name !== columnName
      )
        return sameTypeVariant;

      if (item.affect_on === 'ONLY' && item.column?.column_name === columnName)
        return sameTypeVariant;

      return [];
    })
    .flat(1);
};

export const getDateFormatFn = (
  tsPeriodicity: TimeSeriesPeriod,
): DateFormatFn => {
  switch (tsPeriodicity) {
    case 'DAILY':
      return 'date';
    case 'WEEKLY':
      return 'week';
    case 'MONTHLY':
      return 'month';
    case 'QUARTERLY':
      return 'quarter';
    case 'YEARLY':
      return 'year';
  }
};

export const getBindedTargetValueObject = (
  period: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
  targetValue: number | null | undefined,
  currentTime?: string,
): GoalTarget['values'] | null => {
  if (targetValue == null || targetValue == undefined) return null;

  const DAY_IN_WEEK = 7;
  const DAY_IN_MONTH = dayjs(currentTime).daysInMonth();
  const DAY_IN_QUARTER =
    dayjs(currentTime).endOf('quarter').dayOfYear() -
    dayjs(currentTime).startOf('quarter').dayOfYear() +
    1;
  const DAY_IN_YEAR = dayjs(currentTime).isLeapYear() ? 366 : 365;

  let dailyValue = targetValue;

  switch (period) {
    case 'DAILY': {
      break;
    }
    case 'WEEKLY': {
      dailyValue = targetValue / DAY_IN_WEEK;
      break;
    }
    case 'MONTHLY': {
      dailyValue = targetValue / DAY_IN_MONTH;
      break;
    }
    case 'QUARTERLY': {
      dailyValue = targetValue / DAY_IN_QUARTER;
      break;
    }
    case 'YEARLY': {
      dailyValue = targetValue / DAY_IN_YEAR;
      break;
    }
  }

  return {
    DAILY: dailyValue,
    WEEKLY: dailyValue * DAY_IN_WEEK,
    MONTHLY: dailyValue * DAY_IN_MONTH,
    QUARTERLY: dailyValue * DAY_IN_QUARTER,
    YEARLY: dailyValue * DAY_IN_YEAR,
  };
};

function computeViewColumns({
  display,
  undisplay,
  customColumns,
}: {
  display: ViewConfig['display'];
  undisplay: ViewConfig['undisplay'];
  customColumns: ViewConfig['custom_columns'];
}): ViewColumn[] {
  const latestColumnStatesMap: Record<string, ViewColumn> = {};

  // NOTE: processing order: fx columns => un-display => display
  // this ensure no duplicated ids
  undisplay.forEach((config) => {
    latestColumnStatesMap[config.column_name] = {
      alias: customColumns?.[config.column_name]?.alias ?? null,
      formula: customColumns?.[config.column_name]?.formula ?? null,
      //
      column_name: config.column_name,
      column_type: config.column_type ?? 'TEXT',
      show: false,
      fixed: false,
      index: Infinity,
    };
  });

  display.forEach((config, index) => {
    latestColumnStatesMap[config.column_name] = {
      alias: customColumns?.[config.column_name]?.alias ?? null,
      formula: customColumns?.[config.column_name]?.formula ?? null,
      column_name: config.column_name,
      column_type: config.column_type ?? 'TEXT',
      show: true,
      fixed: config.fixed,
      index,
    };
  });

  const resolvedViewColumns = Object.values(latestColumnStatesMap).sort(
    (a, b) => {
      if (a.show && !b.show) return -1;
      if (!a.show && b.show) return 1;
      return (a.index ?? 0) - (b.index ?? 0);
    },
  );

  return resolvedViewColumns;
}

function getTableSpecialColumnIds(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const groupColumnIds =
    config.column_grouping?.map?.((col) => col.column_name) ?? [];

  const tsDateColumnId = config.grouping?.columns?.find?.(
    (col) => col.column_type === 'DATETIME',
  )?.column_name;

  const metricColumnIds = config.grouping?.sub_aggregates?.map?.(
    (col) => col.column_name,
  );

  return {
    groupColumnIds,
    tsDateColumnId,
    metricColumnIds,
  };
}

// PREDICATORS

function getTableGroupingPredicator(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const { groupColumnIds } = getTableSpecialColumnIds(config);
  return (col: Column) => {
    return groupColumnIds.includes(col.column_name);
  };
}

function getTableDatePredicator(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const { tsDateColumnId } = getTableSpecialColumnIds(config);
  const groupingPredicate = getTableGroupingPredicator(config);
  return (col: Column) => {
    if (groupingPredicate(col)) return false;
    return col.column_name === tsDateColumnId;
  };
}

function getTableMetricPredicator(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const { metricColumnIds } = getTableSpecialColumnIds(config);
  const groupingPredicate = getTableGroupingPredicator(config);
  const datePredicate = getTableDatePredicator(config);
  return (col: Column) => {
    if (groupingPredicate(col)) return false;
    if (datePredicate(col)) return false;
    return metricColumnIds.includes(col.column_name);
  };
}

function getTableDisplayPredicator(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const groupingPredicate = getTableGroupingPredicator(config);
  const datePredicate = getTableDatePredicator(config);
  const metricPredicate = getTableMetricPredicator(config);
  return (col: Column) => {
    if (groupingPredicate(col)) return false;
    if (datePredicate(col)) return false;
    if (metricPredicate(col)) return false;
    return col.show === true;
  };
}

function getTableUnDisplayPredicator(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const groupingPredicate = getTableGroupingPredicator(config);
  const datePredicate = getTableDatePredicator(config);
  const metricPredicate = getTableMetricPredicator(config);
  const displayPredicate = getTableDisplayPredicator(config);
  return (col: Column) => {
    if (groupingPredicate(col)) return false;
    if (datePredicate(col)) return false;
    if (metricPredicate(col)) return false;
    if (displayPredicate(col)) return false;
    // return col.show === false;
    return true;
  };
}

// SORTERS

/**
 * Sort columns by fixed property
 * Fixed columns should be placed at the beginning of the array
 */
function tableDisplayColumnSorter(a: Column, b: Column) {
  if (a.fixed === b.fixed) return 0;
  if (a.fixed === true) return -1;
  return 1;
}

function getTableMetricColumnSorter(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const subAggregates = config.grouping.sub_aggregates;
  return (a: Column, b: Column) => {
    return (
      subAggregates.findIndex((conf) => conf.column_name === a.column_name) -
      subAggregates.findIndex((conf) => conf.column_name === b.column_name)
    );
  };
}

// GETTERS

function sortByArray<T>(
  arrayA: T[],
  arrayB: T[],
  getKey: (item: T) => string,
): T[] {
  // Create a map to store the order of each element in arrayB
  const orderMap = new Map<string, number>();

  // Fill the orderMap with arrayB elements and their indices
  arrayB.forEach((item, index) => {
    orderMap.set(getKey(item), index);
  });

  // Sort arrayA based on the order in arrayB
  return [...arrayA].sort((a, b) => {
    const indexA = orderMap.get(getKey(a)) ?? Infinity; // If not found, assign Infinity (puts unmatched elements at the end)
    const indexB = orderMap.get(getKey(b)) ?? Infinity;
    return indexA - indexB;
  });
}

function getTableGroupingColumns(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const viewColumns = computeViewColumns({
    display: config.display,
    undisplay: config.undisplay,
    customColumns: config.custom_columns,
  });
  return sortByArray(
    viewColumns.filter(getTableGroupingPredicator(config)),
    config.column_grouping,
    (col) => col.column_name,
  );
}

function getTableDisplayColumns(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const viewColumns = computeViewColumns({
    display: config.display,
    undisplay: config.undisplay,
    customColumns: config.custom_columns,
  });
  return viewColumns
    .filter(getTableDisplayPredicator(config))
    .sort(tableDisplayColumnSorter);
}

function getTableMetricColumns(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const viewColumns = computeViewColumns({
    display: config.display,
    undisplay: config.undisplay,
    customColumns: config.custom_columns,
  });

  return viewColumns
    .filter(getTableMetricPredicator(config))
    .sort(getTableMetricColumnSorter(config));
}

function getTableDateColumn(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const viewColumns = computeViewColumns({
    display: config.display,
    undisplay: config.undisplay,
    customColumns: config.custom_columns,
  });
  return viewColumns.find(getTableDatePredicator(config));
}

function getTableUndisplayColumns(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
) {
  const viewColumns = computeViewColumns({
    display: config.display,
    undisplay: config.undisplay,
    customColumns: config.custom_columns,
  });
  return viewColumns.filter(getTableUnDisplayPredicator(config));
}

// SORT UTILS
function changeTableDisplayColumnOrder(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
  columnId: string,
  newIndex: number,
): Column[] | null {
  const displayColumns = getTableDisplayColumns(config);

  const displayColumnsById = displayColumns.reduce((acc, col) => {
    acc[col.column_name] = col;
    return acc;
  }, {} as Record<string, Column>);

  const invisibleDisplayColumns = config.display.filter((col) => {
    return displayColumnsById[col.column_name] === undefined;
  });

  const oldIndex = displayColumns.findIndex(
    (col) => col.column_name === columnId,
  );

  if (oldIndex < 0) return null;

  const sortedColumns = arrayMove(displayColumns, oldIndex, newIndex).sort(
    tableDisplayColumnSorter,
  );

  return sortedColumns
    .map<Column>((col) => ({
      column_name: col.column_name,
      column_type: col.column_type ?? 'ARRAY',
      fixed: col.fixed,
      show: col.show,
    }))
    .concat(invisibleDisplayColumns);
}

function changeTableMetricColumnOrder(
  config: Extract<ViewConfig, { view_type: 'TABLE' }>,
  columnId: string,
  newIndex: number,
): AggregateItem[] | null {
  const oldIndex = config.grouping.sub_aggregates.findIndex(
    (col) => col.column_name === columnId,
  );

  if (oldIndex < 0) return null;

  return arrayMove(config.grouping.sub_aggregates, oldIndex, newIndex);
}

function changeTableGroupingColumnOrder(
  columnGroupingConfig: TableFullConfig['column_grouping'],
  columnId: string,
  newIndex: number,
): TableFullConfig['column_grouping'] | null {
  const oldIndex = columnGroupingConfig.findIndex(
    (col) => col.column_name === columnId,
  );

  if (oldIndex < 0) return null;

  return arrayMove(columnGroupingConfig, oldIndex, newIndex);
}

type ColumnWithID = { column_name: string };

function moveTableColumn<
  Source extends ColumnWithID,
  Target extends ColumnWithID,
>(
  columnId: string,
  from: Source[],
  to: Target[],
  transform: (column: Source) => Target,
): { source: Source[]; target: Target[] } {
  /**
   * LEGACY: Move display column to undisplay
   */
  const predicate = (col: ColumnWithID) => col.column_name === columnId;

  const isAlreadyExist = to.some(predicate);

  if (isAlreadyExist)
    return {
      source: from.filter((col) => col.column_name !== columnId),
      target: to,
    };

  let { source: nextSource, target: nextTarget } = moveItems<Source, Target>(
    from,
    to,
    predicate,
    transform,
  );

  return {
    source: nextSource,
    target: nextTarget,
  };
}

function hideTableColumn(
  columnId: string,
  displayConfig: ViewConfig['display'],
  undisplayConfig: ViewConfig['undisplay'],
) {
  const { source: display, target: undisplay } = moveTableColumn(
    columnId,
    displayConfig,
    undisplayConfig,
    (col) => ({
      ...col,
      fixed: false,
      show: false,
    }),
  );
  return { display, undisplay };
}

function hideTableMetricColumn(
  columnId: string,
  displayConfig: ViewConfig['display'],
  undisplayConfig: ViewConfig['undisplay'],
  metricConfig: ViewConfig['grouping']['sub_aggregates'],
) {
  const { source: metric, target: undisplay } = moveTableColumn(
    columnId,
    metricConfig,
    undisplayConfig,
    (col) => ({
      column_name: col.column_name,
      column_type: col.column_type,
      fixed: false,
      show: false,
    }),
  );

  const display = displayConfig.filter((col) => col.column_name !== columnId);

  return { metric, display, undisplay };
}

function moveTableUndisplayToDisplayColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  undisplayConfig: ViewConfig['undisplay'],
) {
  const { source: undisplay, target: display } = moveTableColumn(
    columnId,
    undisplayConfig,
    displayConfig,
    (col) => ({
      ...col,
      fixed: false,
      show: true,
    }),
  );

  // move to the destination index
  const oldIndex = display.findIndex((conf) => conf.column_name === columnId);

  const nextDisplays = arrayMove(display, oldIndex, destinationIndex);

  return { display: nextDisplays, undisplay };
}

/**
 * Get default aggregate type for a column
 * Also cover custom column with fx
 * @param columnType
 * @param columnFx
 * @param columnsConfig
 * @returns
 */
function getDefaultAggregateType(
  columnType: ColumnType | null,
  columnFx: string | undefined | null,
  columnsConfig: ViewConfig['custom_columns'],
): AggregateType {
  if (isAggFx(columnFx, columnsConfig ?? {})) return 'AGG';
  if (isAnalyticalFx(columnFx, columnsConfig ?? {})) return 'AGG';
  if (columnType == null) return 'COUNT';
  if (columnType === 'NUMBER') return 'SUM';
  return 'COUNT';
}

function moveTableUndisplayToMetricColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  undisplayConfig: ViewConfig['undisplay'],
  metricConfig: ViewConfig['grouping']['sub_aggregates'],
  columnsConfig: ViewConfig['custom_columns'],
) {
  /**
   * Move undisplay -> display first
   */
  const { source: updatedUndisplay, target: updatedDisplay } = moveTableColumn(
    columnId,
    undisplayConfig,
    displayConfig,
    (col) => ({
      ...col,
      fixed: false,
      show: true,
    }),
  );

  const targetColumn = updatedDisplay.find(
    (col) => col.column_name === columnId,
  );

  // fallback
  if (!targetColumn)
    return {
      metric: metricConfig,
      display: displayConfig,
      undisplay: undisplayConfig,
    };

  const isAlreadyInMetric = metricConfig.some(
    (col) => col.column_name === columnId,
  );

  let draftMetrics = [...metricConfig];

  if (!isAlreadyInMetric) {
    draftMetrics.push({
      column_name: targetColumn.column_name,
      column_type: targetColumn.column_type ?? 'ARRAY',
      aggregate_type: getDefaultAggregateType(
        targetColumn.column_type,
        columnsConfig?.[targetColumn.column_name]?.formula,
        columnsConfig,
      ),
    });
  }

  // move to the destination index
  const oldIndex = draftMetrics.findIndex(
    (conf) => conf.column_name === columnId,
  );

  const nextMetrics = arrayMove(draftMetrics, oldIndex, destinationIndex);

  return {
    metric: nextMetrics,
    display: updatedDisplay,
    undisplay: updatedUndisplay,
  };
}

function moveTableDisplayToMetricColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  metricConfig: ViewConfig['grouping']['sub_aggregates'],
  columnsConfig: ViewConfig['custom_columns'],
) {
  const targetColumn = displayConfig.find(
    (col) => col.column_name === columnId,
  );

  if (!targetColumn) return { display: displayConfig, metric: metricConfig };

  const isAlreadyInMetric = metricConfig.some(
    (col) => col.column_name === columnId,
  );

  let draftMetrics = [...metricConfig];

  if (!isAlreadyInMetric) {
    draftMetrics.push({
      column_name: targetColumn.column_name,
      column_type: targetColumn.column_type ?? 'ARRAY',
      aggregate_type: getDefaultAggregateType(
        targetColumn.column_type,
        columnsConfig?.[targetColumn.column_name]?.formula,
        columnsConfig,
      ),
    });
  }

  // move to the destination index
  const oldIndex = draftMetrics.findIndex(
    (conf) => conf.column_name === columnId,
  );

  const nextMetrics = arrayMove(draftMetrics, oldIndex, destinationIndex);

  return {
    metric: nextMetrics,
  };
}

function moveTableMetricToDisplayColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  metricConfig: ViewConfig['grouping']['sub_aggregates'],
) {
  const target = metricConfig.find((col) => col.column_name === columnId);

  const nextMetricConfig = metricConfig.filter(
    (col) => col.column_name !== columnId,
  );

  let oldIndex = -1;
  let nextDisplay = displayConfig.map((col, index) => {
    if (col.column_name === columnId) {
      oldIndex = index;
      return { ...col, show: true, fixed: false };
    }
    return col;
  });

  if (oldIndex === -1 && target) {
    nextDisplay.push({
      column_name: columnId,
      column_type: target.column_type,
      fixed: false,
      show: true,
    });
    oldIndex = nextDisplay.length - 1;
  }

  nextDisplay = arrayMove(nextDisplay, oldIndex, destinationIndex);

  return { display: nextDisplay, metrics: nextMetricConfig };
}

function moveTableDisplayToGroupingColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  columnGroupingConfig: TableFullConfig['column_grouping'],
) {
  const targetColumn = displayConfig.find(
    (col) => col.column_name === columnId,
  );

  if (!targetColumn)
    return { display: displayConfig, column_grouping: columnGroupingConfig };

  const isAlreadyInGrouping = columnGroupingConfig.some(
    (col) => col.column_name === columnId,
  );

  let draftGrouping = [...columnGroupingConfig];

  if (!isAlreadyInGrouping) {
    draftGrouping.push({
      column_name: targetColumn.column_name,
      column_type: targetColumn.column_type ?? 'ARRAY',
    });
  }

  // move to the destination index
  const oldIndex = draftGrouping.findIndex(
    (conf) => conf.column_name === columnId,
  );

  const nextGrouping = arrayMove(draftGrouping, oldIndex, destinationIndex);

  const nextDisplay = displayConfig.map((col) => {
    if (col.column_name === columnId) {
      return { ...col, fixed: true, show: true };
    }
    return col;
  });

  return { display: nextDisplay, column_grouping: nextGrouping };
}

function moveTableUndisplayToGroupingColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  undisplayConfig: ViewConfig['undisplay'],
  columnGroupingConfig: TableFullConfig['column_grouping'],
) {
  /**
   * Move undisplay -> display first
   */
  const { source: updatedUndisplay, target: updatedDisplay } = moveTableColumn(
    columnId,
    undisplayConfig,
    displayConfig,
    (col) => ({
      ...col,
      fixed: true,
      show: true,
    }),
  );

  const targetColumn = updatedDisplay.find(
    (col) => col.column_name === columnId,
  );

  // fallback
  if (!targetColumn)
    return {
      column_grouping: columnGroupingConfig,
      display: displayConfig,
      undisplay: undisplayConfig,
    };

  const isAlreadyInGrouping = columnGroupingConfig.some(
    (col) => col.column_name === columnId,
  );

  let draftGrouping = [...columnGroupingConfig];

  if (!isAlreadyInGrouping) {
    draftGrouping.push({
      column_name: targetColumn.column_name,
      column_type: targetColumn.column_type ?? 'ARRAY',
    });
  }

  // move to the destination index
  const oldIndex = draftGrouping.findIndex(
    (conf) => conf.column_name === columnId,
  );

  const nextGrouping = arrayMove(draftGrouping, oldIndex, destinationIndex);

  return {
    column_grouping: nextGrouping,
    display: updatedDisplay,
    undisplay: updatedUndisplay,
  };
}

function hideTableGroupingColumn(
  columnId: string,
  displayConfig: ViewConfig['display'],
  undisplayConfig: ViewConfig['undisplay'],
  columnGroupingConfig: TableFullConfig['column_grouping'],
) {
  const { source: columnGrouping, target: undisplay } = moveTableColumn(
    columnId,
    columnGroupingConfig,
    undisplayConfig,
    (col) => ({
      column_name: col.column_name,
      column_type: col.column_type,
      fixed: false,
      show: false,
    }),
  );

  const display = displayConfig.filter((col) => col.column_name !== columnId);

  return { column_grouping: columnGrouping, display, undisplay };
}

function moveTableGroupingToDisplayColumn(
  columnId: string,
  destinationIndex: number,
  displayConfig: ViewConfig['display'],
  columnGroupingConfig: TableFullConfig['column_grouping'],
) {
  const target = columnGroupingConfig.find(
    (col) => col.column_name === columnId,
  );

  const nextGroupingConfig = columnGroupingConfig.filter(
    (col) => col.column_name !== columnId,
  );

  let oldIndex = -1;
  let nextDisplay = displayConfig.map((col, index) => {
    if (col.column_name === columnId) {
      oldIndex = index;
      return { ...col, show: true, fixed: false };
    }
    return col;
  });

  if (oldIndex === -1 && target) {
    nextDisplay.push({
      column_name: columnId,
      column_type: target.column_type,
      fixed: false,
      show: true,
    });
    oldIndex = nextDisplay.length - 1;
  }

  nextDisplay = arrayMove(nextDisplay, oldIndex, destinationIndex);

  return { display: nextDisplay, column_grouping: nextGroupingConfig };
}

const ViewConfigUtils = {
  computeViewColumns,
  getTableDateColumn,
  getTableMetricColumns,
  getTableDisplayColumns,
  getTableUndisplayColumns,
  getTableGroupingColumns,
  hideTableColumn,
  hideTableMetricColumn,
  hideTableGroupingColumn,
  moveTableUndisplayToDisplayColumn,
  changeTableDisplayColumnOrder,
  changeTableMetricColumnOrder,
  changeTableGroupingColumnOrder,
  moveTableMetricToDisplayColumn,
  moveTableDisplayToMetricColumn,
  moveTableUndisplayToMetricColumn,
  moveTableDisplayToGroupingColumn,
  moveTableUndisplayToGroupingColumn,
  moveTableGroupingToDisplayColumn,
};

export default ViewConfigUtils;
