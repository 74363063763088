'use client';
import React, { useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $nodesOfType } from 'lexical';

import { ModelPropertyNode } from '../model-property-node';
import { useModelPropertyNodeRegister } from './ColumnParserPlugin';

type Props = {
  onPropertiesChange: (latestProperties: string[]) => void;
};

export const ModelPropertyInfoPlugin: React.FC<Props> = ({
  onPropertiesChange: onChange,
}) => {
  const [editor] = useLexicalComposerContext();

  useModelPropertyNodeRegister();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const nodes = $nodesOfType(ModelPropertyNode);
        const currentProperties = nodes.map((node) => node.getPropertyName());
        onChange(currentProperties);
      });
    });
  }, [editor, onChange]);

  return null;
};
