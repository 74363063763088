'use client';
import type { FxIssue } from '@/api-error';
import type { ColumnType } from '@/models/view';

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import invariant from 'tiny-invariant';

export type EditFxPayload = {
  columnId: string;
  alias: string;
  formula: string;
  onSubmit: (newPayload: { formula: string; columnType: ColumnType }) => void;
  initErrors?: {
    formula?: FxIssue;
  };
};

type FormulaColumnDialogContextType = {
  isCreateDialogOpen: boolean;
  editFxPayload: EditFxPayload | null;
  openCreateDialog: () => void;
  openEditDialog: (editFxPayload: EditFxPayload) => void;
  closeDialog: () => void;
};

const NOOP = () => {};

const FxColumnDialogContext = createContext<FormulaColumnDialogContextType>({
  isCreateDialogOpen: false,
  editFxPayload: null,
  openCreateDialog: NOOP,
  openEditDialog: NOOP,
  closeDialog: NOOP,
});

const { Provider } = FxColumnDialogContext;

export const FxColumnDialogProvider = ({ children }: PropsWithChildren<{}>) => {
  //
  const [dialogState, setDialogState] = useState<{
    isCreateDialogOpen: boolean;
    editFxPayload: EditFxPayload | null;
  }>({ isCreateDialogOpen: false, editFxPayload: null });

  return (
    <Provider
      value={{
        isCreateDialogOpen: dialogState.isCreateDialogOpen,
        editFxPayload: dialogState.editFxPayload,
        openCreateDialog: useCallback<
          FormulaColumnDialogContextType['openCreateDialog']
        >(() => {
          setDialogState({ isCreateDialogOpen: true, editFxPayload: null });
        }, []),
        openEditDialog: useCallback<
          FormulaColumnDialogContextType['openEditDialog']
        >((editFxPayload) => {
          setDialogState({
            isCreateDialogOpen: false,
            editFxPayload: editFxPayload,
          });
        }, []),
        closeDialog: useCallback<
          FormulaColumnDialogContextType['closeDialog']
        >(() => {
          setDialogState({ isCreateDialogOpen: false, editFxPayload: null });
        }, []),
      }}
      //
    >
      {children}
    </Provider>
  );
};

export const useFxColumnDialogContext = () => {
  const context = useContext(FxColumnDialogContext);
  invariant(context, 'this must be registered under FxColumnDialogProvider.');
  return context;
};
