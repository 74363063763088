import dayjs from 'dayjs';

import { DashboardFilterItem } from '@/feature-dashboard/dashboard/model';
import { DataModelDto } from '@/models/data-model';
import { getChartDefaultConfig } from '@/utils/view-config';

import { ISO_DATE_FORMAT } from '../constants';
import {
  AdvancedFilter,
  FilterItem,
  TimeSeriesPeriod,
  ViewConfig,
  ViewDto,
  ViewSchema,
  zCommonConfig,
} from '../models/view';
import {
  fromC2pConfigToPastRange,
  getDateTimeFromDateFilter,
  getWeekStart,
  RANGED_DATES_FN_MAPPER,
} from '../utils/date';
import { PARAMS_PER_PAGE_TABLE_NO_GROUPING } from './table-view/constant';
import { ReportRequestBody } from './types';

export const getInitializedViewSettings = (
  viewSettings: ViewDto,
  dataModel: DataModelDto,
): ViewDto => {
  try {
    // NOTE: init view_type could be any charts included TABLE, PIE, LINE, .etc
    const parsed = ViewSchema.parse(viewSettings);
    const defaultConfig = getChartDefaultConfig(parsed.config.view_type);
    const common = zCommonConfig.parse({});

    parsed.config = {
      ...defaultConfig,
      ...common,
      timezone: parsed.config.timezone,
      display: (dataModel?.columns || []).map((c) => ({
        column_name: c.property,
        column_type: c.type,
        show: true,
        fixed: null,
      })),
    } as ViewConfig;

    return parsed;
  } catch (error) {
    console.log('getInitializedViewSettings error', error);

    const newViewSettings = structuredClone(viewSettings);
    newViewSettings.config = {
      view_type: 'TABLE',
      filter: [],
      advanced_filters: [],

      compare_with_past: null,
      grouping: {
        columns: [],
        sub_aggregates: [],
      },
      color_conditions: [],
      sort: [],
      undisplay: [],
      time_series: null,
      display:
        dataModel?.columns?.map((c) => ({
          column_name: c.property,
          column_type: c.type,
          show: true,
          fixed: null,
        })) ?? [],
      pagination: {
        page: 1,
        perPage: PARAMS_PER_PAGE_TABLE_NO_GROUPING,
      },
      timezone: newViewSettings.config.timezone,
      column_grouping: [],
      fixed_columns: [],
    };

    return newViewSettings;
  }
};

export const convertDFilterToRFilter = (
  filter: DashboardFilterItem,
): AdvancedFilter => {
  return {
    name: filter.name,
    is_active: filter.is_active,
    logical_operator: filter.logical_operator,
    column_filters: filter.column_filters.map((item) => item.column_filter),
  };
};

export function getRangeDatesFromFilter(
  dateFilter: FilterItem,
  periodicity: TimeSeriesPeriod,
): string[] {
  if (dateFilter.column_type !== 'DATETIME') return [];

  const {
    from_time: fromTime,
    to_time: toTime,
    first_day_of_week,
  } = getDateTimeFromDateFilter(dateFilter);
  const weekStart = getWeekStart(first_day_of_week);

  return RANGED_DATES_FN_MAPPER[periodicity](
    dayjs(fromTime).format(ISO_DATE_FORMAT),
    dayjs(toTime).format(ISO_DATE_FORMAT),
    weekStart,
  );
}

export function getPastRangeDatesFromFilter(
  dateFilter: FilterItem,
  periodicity: TimeSeriesPeriod,
  c2pConfig: Exclude<ViewConfig['compare_with_past'], null | undefined>,
): string[] {
  if (dateFilter.column_type !== 'DATETIME') return [];

  const {
    from_time: fromTime,
    to_time: toTime,
    first_day_of_week,
  } = getDateTimeFromDateFilter(dateFilter);

  const weekStart = getWeekStart(first_day_of_week);

  const { fromDate: pastFromTime, toDate: pastToTime } =
    fromC2pConfigToPastRange({
      c2pConfig,
      periodicity,
      currentStartDate: fromTime,
      currentEndDate: toTime,
      weekStart,
    });

  if (!pastFromTime || !pastToTime) return [];

  const pastRangeDate = RANGED_DATES_FN_MAPPER[periodicity](
    pastFromTime,
    pastToTime,
    weekStart,
  );

  return pastRangeDate;
}

export function getReportQKFromBody(body: ReportRequestBody | null) {
  if (!body) {
    return [];
  }
  return [
    body.data.source.name,
    body.data.source.columns,
    body.data.source.custom_formula_columns,
    body.data.loader.filters,
    body.data.loader.first_day_of_week,
    body.data.loader.groupBy,
    body.data.loader.sort,
    body.data.loader.pagination,
  ];
}
