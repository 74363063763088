import { z } from 'zod';

import { ALPHANUMERIC } from '../shared/constants';
import { isFxValid } from '../utils';

export const zFxForm = z.object({
  columnName: z
    .string()
    .min(1, 'fx-invalid-column-name-required')
    .max(128, 'fx-invalid-column-name-max-length'),
  // .refine((colId) => {
  //   const regEx = new RegExp(`^[${ALPHANUMERIC}\\s_]+$`, 'i');
  //   const matchArr = regEx.exec(colId);
  //   return matchArr != null;
  // }, 'invalid-column-id'),
  formula: z.string().refine((fx) => isFxValid(fx), 'invalid-formula-syntax'),
});

export const zFxEditForm = zFxForm;

export type FxFormPayload = z.infer<typeof zFxForm>;

export type FxEditFormPayload = FxFormPayload;
