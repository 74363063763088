import invariant from 'tiny-invariant';
import { z } from 'zod';

import {
  ApiAdapterRequestHeaders,
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';
import {
  BatchDeleteCollectionsPayload,
  type Collection,
  type CreateCollectionPayload,
  type TemplateData,
  TransformationCollection,
  TransformationDagPayload,
  TransformationDagQuery,
  TransformationDataModels,
  type UpdateCollectionPayload,
  zTransformationCollection,
} from '@/feature-transformation/model';
import { NO_PERMISSION_ACCESS_TEAM_API_ERROR } from '@/initializers/AppErrorBoundary';
import { serializeData } from '@/utils/data';
import { isUuid } from '@/utils/string';

import { clientHttp, serverHttp } from './axios';
import { dataModelsURL, dataTransformationUrlMap } from './url-string';

const zSuitableConnection = z.object({
  connection_id: z.string(),
  connection_name: z.string(),
});

const zConnectionsByDataSourceId = z.record(
  z.string(),
  z.array(zSuitableConnection).nullish(),
);

export type SuitableConnection = z.infer<typeof zSuitableConnection>;

// type ConnectionsByDataSourceId = z.infer<typeof zConnectionsByDataSourceId>;

const zTemplateConnectionsResponse = z.object({
  data_source_id_to_suitable_connections: zConnectionsByDataSourceId,
});

export type TemplateConnectionsResponse = z.infer<
  typeof zTemplateConnectionsResponse
>;

const zTemplatePreviewRequest = z.object({
  connection_ids: z.array(z.string()),
});

export type TemplatePreviewRequest = z.infer<typeof zTemplatePreviewRequest>;

const zTemplatePreviewRecord = z.record(
  z.string(),
  z.union([z.string(), z.number(), z.null()]),
);

export type TemplatePreviewRecord = z.infer<typeof zTemplatePreviewRecord>;

const zQueryExecutionRequest = z.object({
  query: z.string(),
});

export type QueryExecutionRequest = z.infer<typeof zQueryExecutionRequest>;

const zQueryExecutionResponse = z.array(zTemplatePreviewRecord).nullish();

export type QueryExecutionResponse = z.infer<typeof zQueryExecutionResponse>;

const zTemplatePreviewQueryResponse = z.string();

export type TemplatePreviewQueryResponse = z.infer<
  typeof zTemplatePreviewQueryResponse
>;

const zCreateDataModelFromQueryRequest = z.object({
  query: z.string(),
  parent_collection_id: z.string(),
  description: z.string().nullish(),
  id: z.string().nullish(),
});

export type CreateDataModelFromQueryRequest = z.infer<
  typeof zCreateDataModelFromQueryRequest
>;

const zDataModelData = z.object({
  id: z.string(),
  team_id: z.string(),
  owner_id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});

export type DataModelData = z.infer<typeof zDataModelData>;

class DataTransformationApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  private invariantUUID(uuid: string) {
    invariant(uuid, 'no team id provided');

    if (!isUuid(uuid)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }
  }

  private clone() {
    return new DataTransformationApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(headers: ApiAdapterRequestHeaders) {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  // TEMPLATES

  async getTemplates(teamId?: string | null) {
    const queryParams = new URLSearchParams({
      size: '1000',
      ...(teamId && {
        team_id: teamId,
      }),
    });
    return await this.get<TemplateData[]>(
      dataTransformationUrlMap['templates']() + '?' + queryParams.toString(),
    );
  }

  async getTemplateConnections(teamId: string, templateId: string) {
    return await this.get<TemplateConnectionsResponse>(
      dataTransformationUrlMap['templateConnections'](teamId, templateId),
    );
  }

  async getTemplatePreviewQuery(
    teamId: string,
    templateId: string,
    connectionIds: string[],
  ) {
    return await this.request<
      TemplatePreviewQueryResponse,
      TemplatePreviewRequest
    >(
      dataTransformationUrlMap['templatePreviewQuery'](teamId, templateId),
      'post',
      { connection_ids: connectionIds },
    );
  }

  async executeQuery(teamId: string, sqlQuery: string) {
    return await this.request<QueryExecutionResponse, QueryExecutionRequest>(
      dataTransformationUrlMap['executeQuery'](teamId),
      'post',
      { query: sqlQuery },
      {},
      {},
      {
        timeout: 10 * 1000 * 60, // 10 mins
      },
    );
  }

  // DATA_MODEL
  async createDataModelFromQuery(
    teamId: string,
    payload: CreateDataModelFromQueryRequest,
  ) {
    return await this.request<
      TransformationDataModels,
      CreateDataModelFromQueryRequest
    >(
      dataTransformationUrlMap['createDataModelFromQuery'](teamId),
      'post',
      payload,
    );
  }

  // COLLECTIONS
  async getCollections({ teamId }: { teamId: string }) {
    return (
      (await this.get<Collection[]>(
        dataTransformationUrlMap['collections'](teamId),
      )) ?? []
    );
  }

  async createCollection(
    teamId: string,
    payload: CreateCollectionPayload,
  ): Promise<Collection | null> {
    return await this.request<Collection, CreateCollectionPayload>(
      dataTransformationUrlMap['collections'](teamId),
      'post',
      payload,
    );
  }

  async editCollection(
    teamId: string,
    collectionId: string,
    payload: UpdateCollectionPayload,
  ): Promise<Collection | null> {
    return await this.request<Collection, UpdateCollectionPayload>(
      dataTransformationUrlMap['getCollectionById'](teamId, collectionId),
      'put',
      payload,
    );
  }

  async deleteCollection(teamId: string, collectionId: string) {
    await this.request(
      dataTransformationUrlMap['getCollectionById'](teamId, collectionId),
      'delete',
    );
  }

  async batchDeleteCollection(
    teamId: string,
    payload: BatchDeleteCollectionsPayload,
  ) {
    await this.request<void, BatchDeleteCollectionsPayload>(
      dataTransformationUrlMap['batchDeleteCollection'](teamId),
      'delete',
      payload,
    );
  }

  async getCollectionById(teamId: string, collectionId: string) {
    const collectionDetail = await this.get<TransformationCollection>(
      dataTransformationUrlMap['getCollectionById'](teamId, collectionId),
    );
    return serializeData(collectionDetail, zTransformationCollection);
  }

  async editDataModel(
    teamId: string,
    datasetId: string,
    dataModelId: string,
    payload: UpdateCollectionPayload,
  ) {
    return await this.request<
      TransformationDataModels,
      UpdateCollectionPayload
    >(
      dataModelsURL.editDataModel(teamId, datasetId, dataModelId),
      'put',
      payload,
    );
  }

  async editDataModelQueryString(
    teamId: string,
    datasetId: string,
    dataModelId: string,
    payload: { query: string },
  ) {
    return await this.request<string, { query: string }>(
      dataModelsURL.editDataModelQueryString(teamId, datasetId, dataModelId),
      'put',
      payload,
    );
  }

  getDagNodes(teamId: string) {
    return this.get<TransformationDagQuery>(
      dataTransformationUrlMap.getDagNodes(teamId),
    );
  }

  editDagNodesConfig(teamId: string, payload: Array<TransformationDagPayload>) {
    // NOTE: payload maybe contain more than required fields
    const safePayload = payload.map((item) => {
      if (item.category === 'DATASET') {
        return {
          id: item.id,
          dag_config: item.dag_config,
          category: item.category,
        };
      }

      if (item.category) {
        return {
          dataset_id: item.dataset_id,
          id: item.id,
          dag_config: item.dag_config,
          category: item.category,
        };
      }
    });
    return this.request(
      dataTransformationUrlMap.editDagNodesConfig(teamId),
      'put',
      safePayload,
    );
  }
}

/**
 * @deprecated
 */
export const DataTransformationClientApiAdapter = Object.freeze(
  new DataTransformationApiAdapterClass(clientHttp),
);
/**
 * @deprecated
 */
export const DataTransformationServerApiAdapter = Object.freeze(
  new DataTransformationApiAdapterClass(serverHttp),
);

export const DataTransformationApi = {
  onBrowser: () => new DataTransformationApiAdapterClass(clientHttp),
  onServer: () => new DataTransformationApiAdapterClass(serverHttp),
};
