import type { SxProps, Theme } from '@mui/material/styles';
import type { EditorThemeClasses } from 'lexical';

export const PARAGRAPH_CLASS = 'CigroLexicalParagraph';
export const MODEL_PROPERTY_CLASS = 'CigroLexicalModelProperty';
export const MODEL_PROPERTY_ALIAS_ATTR = 'data-lexical-alias';
export const MODEL_PROPERTY_COLUMN_ID_ATTR = 'data-lexical-column-id';

export const CIGRO_THEME: EditorThemeClasses = {
  paragraph: PARAGRAPH_CLASS,
  modelProperty: MODEL_PROPERTY_CLASS,
};

export const NAMESPACE = 'CigroFormulaEditor';

export const FX_EDITOR_THEME: SxProps<Theme> = {
  p: '8px',
  borderRadius: '8px',
  outline: 'none',
  maxHeight: '204px',
  overflowY: 'auto',
  caretColor: (t) => t.palette.neutralV2[0],
  boxShadow: (t) => `0 0 0 1px ${t.palette.neutralV2[5]}`,
  typography: 'subhead6',
};

export const FX_COMPONENT_THEME: SxProps<Theme> = {
  [`.${PARAGRAPH_CLASS}`]: {
    m: 0,
    color: 'neutralV2.0',
  },
  [`.${MODEL_PROPERTY_CLASS}`]: {
    // color: 'transparent',
    // position: 'relative',
    whiteSpace: 'nowrap',
    // [`&::after`]: {
    //   content: `attr(${MODEL_PROPERTY_ALIAS_ATTR})`,
    //   position: 'absolute',
    //   left: 0,
    //   // bgcolor: 'primary.10',
    //   bgcolor: 'transparent',
    //   outline: (t) => `0.5px solid ${t.palette.primary[20]}`,
    //   outlineOffset: '-1.5px',
    //   color: 'primary.100',
    //   borderRadius: '4px',
    //   maxWidth: '100%',
    //   width: '100%',
    //   textOverflow: 'ellipsis',
    //   whiteSpace: 'nowrap',
    //   textAlign: 'center',
    //   overflowX: 'hidden',
    //   // transition: (t) =>
    //   //   t.transitions.create('opacity', {
    //   //     delay: 100,
    //   //   }),
    // },

    // [`&:hover`]: {
    //   color: 'primary.100',
    //   [`&::after`]: {
    //     opacity: 0,
    //   },
    // },

    color: 'primary.100',
  },
};
